import React, { useEffect, useState } from 'react'
import { headersTypes, MOLTablePropTypes } from './MOLTablePropTypes';
import { HiOutlineSortAscending, HiOutlineSortDescending } from 'react-icons/hi'
import { ImDatabase } from 'react-icons/im'

import ATMSkeleton from '../../atoms/ATMSkeleton/ATMSkeleton';

const MOLTable = ({
    headers,
    rows,
    size,
    selectedRows = [],
    onRowSelect = (rows: any) => { },
    sortValue = { field: "", value: "DESC" },
    onSortValueChange = () => { },
    isCheckbox,
    onRowClick,
    isLoading = false,
    rowsPerPage = 10,
}: MOLTablePropTypes
) => {

    const [flexTotal, setFlexTotal] = useState(0);

    useEffect(() => {
        setFlexTotal(headers.reduce((acc, ele: { name: string; flex?: number }) => (acc + (ele.flex || 0)), 0))
    }, [headers])

    return (
        <>
            <div className='w-full shadow-lg h-full '>

                <table className='w-full border-collapse'>

                    {/* Table Head */}
                    <thead className='w-full relative'>
                        <tr className='sticky top-0 left-0 right-0  border-b bg-primary-50 border-primary-300 w-full' >
                            {
                                isCheckbox && headers.length &&
                                <td align='center' width="2%" className={`  ${size === 'large' ? 'py-3' : 'py-2'} `}>
                                    <input
                                        className='cursor-pointer '
                                        type='checkbox'
                                        checked={selectedRows.length === rows.length}
                                        onChange={(e) => { e.stopPropagation(); selectedRows.length === rows.length ? onRowSelect([]) : onRowSelect(rows) }}
                                    />
                                </td>
                            }
                            {
                                headers.map((header: headersTypes, index) => {
                                    const width = (header.flex || 0) * 100 / flexTotal
                                    return (
                                        <td
                                            key={header.field}
                                            width={`${width}%`}
                                            className={`${index !== headers.length - 1 && "border-r"} border-white px-2 ${size === 'large' ? 'py-3' : 'py-2'} `}
                                        >
                                            <div className={`flex justify-between items-center font-semibold text-slate-600 text-xs ${header.isSortable && !isLoading && rows.length && "cursor-pointer"}`} onClick={() => header.isSortable && !isLoading && rows.length && onSortValueChange({ field: header.field, value: sortValue.value === 'ASC' ? 'DESC' : "ASC" })} >
                                                <div className='text-primary-500' >
                                                    {header.name}
                                                </div>
                                                {
                                                    header.isSortable && !isLoading && rows.length ?
                                                        (
                                                            sortValue.field === header.field && sortValue.value === 'DESC' ?
                                                                < div className={`text-base ${sortValue.field === header.field ? "text-primary-700" : "text-slate-400"}`}>
                                                                    <HiOutlineSortDescending />
                                                                </div>
                                                                :
                                                                < div className={`text-base ${sortValue.field === header.field ? "text-primary-700" : "text-slate-400"}`}   >
                                                                    <HiOutlineSortAscending />
                                                                </div>
                                                        )
                                                        : null
                                                }
                                            </div>
                                        </td>
                                    )
                                })
                            }
                        </tr>
                    </thead>

                    {/* Table Body */}
                    <tbody>
                        {
                            isLoading ?
                                (
                                    Array(rowsPerPage).fill(0).map((_, index) => (
                                        <tr
                                            key={index}
                                            className={`border-b border-slate-100`}
                                        >
                                            {
                                                isCheckbox && headers.length &&
                                                <td align='center' width="2%" className={` border-slate-300  px-1 `}>
                                                    <ATMSkeleton width="" height='40px' />
                                                </td>
                                            }
                                            {
                                                headers.map((header, index) => (
                                                    <td
                                                    key={index}
                                                        className={` border-slate-300 text-slate-700  px-2 `}
                                                    >
                                                        <ATMSkeleton count={1} width="50%" height="40px" />
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    ))
                                )
                                :
                                (
                                    rows.length ?
                                        (
                                            rows.map((row: any, index) => (
                                                <tr
                                                    key={index}
                                                    onClick={() => onRowClick && onRowClick(row)}
                                                    className={`${onRowClick && 'cursor-pointer hover:bg-slate-100'} border-b border-slate-100 ${index % 1 !== 0 && ' bg-stone-100'}`}
                                                >
                                                    {
                                                        isCheckbox && headers.length &&
                                                        <td align='center' width="2%" className={` border-slate-300  px-1 ${size === 'large' ? "py-2" : "py-1"}`}>
                                                            <input
                                                                className='cursor-pointer'
                                                                type='checkbox'
                                                                checked={selectedRows.findIndex((ele: any) => ele._id === row._id) !== -1}
                                                                onChange={(e) => { e.stopPropagation(); onRowSelect((selectedRows: any) => selectedRows.findIndex((ele: any) => ele._id === row._id) === -1 ? [...selectedRows, row] : selectedRows.filter((selectedRow: any) => selectedRow._id !== row._id)) }}
                                                            />
                                                        </td>
                                                    }
                                                    {
                                                        headers.map((header, index) => (
                                                            <td
                                                                key={header.field}
                                                                className={` border-slate-300 text-slate-700  px-2 ${size === 'large' ? "py-2 text-sm" : "py-1 text-xs"}  `}
                                                            >
                                                                {header.renderCell ? header.renderCell(row) : row[header.field]}
                                                            </td>

                                                        ))
                                                    }
                                                </tr>
                                            ))
                                        )
                                        :
                                        (
                                            <tr>
                                                <td>

                                                    <div style={{ width: `${flexTotal * 100 / headers[0].flex}%` }} className={`flex flex-col justify-center items-center py-5`} >

                                                        <div className='text-4xl mb-2 text-primary-300' >
                                                            <ImDatabase />

                                                        </div>
                                                        <div className='text-slate-700'>
                                                            No Data Found
                                                        </div>
                                                        <div className='text-slate-400 text-sm'>
                                                            Sorry, we couldn't find any data.
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )


                                )
                        }


                    </tbody>
                </table>
            </div>
        </>
    )
}

export default MOLTable
