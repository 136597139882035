import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit'
import { AllWebsitePagesDataType } from "../../models/websitePage.model";

export interface WebsitePageSliceStateType {
    items: AllWebsitePagesDataType[] | [],
    totalItems: number,
    isTableLoading: boolean,
    page: number;
    rowsPerPage: number;
    searchValue: string;
    sortValue: { field: string; value: 'DESC' | 'ASC' }
    filterBy: { fieldName: string; value: string[] | number[]; }[]
}

const initialState: WebsitePageSliceStateType = {
    items: [],
    totalItems: 0,
    isTableLoading: false,
    page: 1,
    rowsPerPage: 10,
    searchValue: "",
    sortValue: { field: 'dealerCode', value: 'ASC' },
    filterBy: [
        {
            fieldName: "mode",
            value: []
        }
    ]
}

const websitePageSlice: any = createSlice({
    name: 'websitepage',
    initialState,
    reducers: {
        setItems: (state, action: PayloadAction<AllWebsitePagesDataType[] | []>) => {
            state.items = action.payload
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
            document.getElementById('scroll-top')?.scrollTo(0, 0)
        },
        setRowsPerPage: (state, action: PayloadAction<number>) => {
            state.rowsPerPage = action.payload;
            state.page = 1;
            document.getElementById('scroll-top')?.scrollTo(0, 0)
        },
        setSearchValue: (state, action: PayloadAction<string>) => {
            state.searchValue = action.payload
            state.page = 1;
        },
        setSortValue: (state, action: PayloadAction<{ field: string; value: 'DESC' | 'ASC' }>) => {
            state.sortValue = action.payload
        },
        setTotalItems: (state, action: PayloadAction<number>) => {
            state.totalItems = action.payload
        },
        setIsTableLoading: (state, action: PayloadAction<boolean>) => {
            state.isTableLoading = action.payload
        },
        setFilterBy: (state, action: PayloadAction<{ fieldName: string; value: string[] | number[]; }[]>) => {
            state.filterBy = action.payload
        },
    }
})

export const {
    setItems,
    setPage,
    setRowsPerPage,
    setSearchValue,
    setSortValue,
    setTotalItems,
    setIsTableLoading,
    setFilterBy
} = websitePageSlice.actions
export default websitePageSlice.reducer
