import { ATMButtonPropType } from './ATMButttonProp'
import { twMerge } from "tailwind-merge";

const ATMButton = ({
  text = "Cancel",
  extraClasses = "",
  onClick = () => { },
  type = "button",
  disabled = false,
  loading = false,
  hidden = false,
}: ATMButtonPropType) => {
  return (
    <button
      type={type}
      className={twMerge(
        `
        bg-primary-400 
        py-2 
        flex 
        justify-center 
        items-center 
        text-btn 
        rounded 
        text-white
        ${extraClasses}
        `
      )}
      disabled={disabled}
      onClick={e => onClick(e)}
    >
      {text}
      {loading ?
        <svg className="animate-spin h-4 w-4 border-2 ml-3 text-white" viewBox="0 0 24 24">
        </svg> : null}
    </button>
  )
}
export default ATMButton
