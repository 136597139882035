import { Skeleton } from '@mui/material'
import React from 'react'
import { ATMSkeletonPropTypes } from './ATMSkeletonPropTypes'

const ATMSkeleton = ({
    count = 1,
    width = '100%',
    height = "80px",
    extraClasses,
}: ATMSkeletonPropTypes
) => {


    return (
        <>
            {
                Array(count).fill(0).map((_, index) => {
                    return (
                        <Skeleton key={index} animation='wave' width={width} height={height} className={extraClasses} />

                    )
                })
            }
        </>
    )
}

export default ATMSkeleton
