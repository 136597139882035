

import React, { useEffect, useState } from 'react'
import { HiOutlineDocumentAdd } from 'react-icons/hi'
import { MdModeEditOutline, } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { headersTypes } from '../../../components/UI/molecules/MOLTable/MOLTablePropTypes'
import { setIsTableLoading, setItems, setPage, setRowsPerPage, setSearchValue, setSortValue, setTotalItems } from '../../../redux/slices/websitePageSlice'
import { AppDispatch, RootState } from '../../../redux/store'
// import { useGetOrdersWithPaginationQuery } from '../../../services/OrderServices'
import { useGetAllWebsitePageDataWithPaginationQuery } from '../../../services/websitePageService'
// import { useGetAllWebsiteDataWithPaginationQuery } from '../../../services/WebsiteService'
import SingleWebsiteList from './SingleWebsiteList'

// import moment from 'moment'
// useGetOrderCountQuery,


const SingleWebsiteInfoWrapper = () => {
   const navigate = useNavigate()
   const dispatch = useDispatch<AppDispatch>()
   const { search } = useLocation()

   let queryValue = new URLSearchParams(search).get('search')
   
   const headers: headersTypes[] = [

      // {
      //    name: "Website page Id",
      //    field: "website_page_id",
      //    isSortable: false,
      //    flex: 2,
      //    // renderCell: (row: any) => {
      //    //     return row.idtag + row.order_id
      //    // }
      // },
      {
         name: "Page Name",
         field: "page_name",
         isSortable: false,
         flex: 2
      },
      {
         name: "URL",
         field: "url",
         isSortable: false,
         flex: 2,
         // renderCell: (row: any) => {
         //     let date = format(new Date(row.date), 'yyyy-MM-dd hh:mm a')
         //     return date
         // }
      },

      {
         name: "page url",
         field: "page_url",
         isSortable: false,
         flex: 2
      },
      // {
      //     name: "Product Tag",
      //     field: "idtag",
      //     isSortable: false,
      //     flex: 2,

      // },
      {
         name: "Action",
         field: "Action",
         isSortable: false,
         flex: 2,
         renderCell: (row: any) => {

            return (<div className='flex p-2 px-4 text-center'>
               <HiOutlineDocumentAdd className="text-3xl mr-5" title='View Website info' onClick={() => navigate(`/website-page-view?website_id=${row.website_id}&website_page_id=${row.website_page_id}`)} />
               <MdModeEditOutline className="text-3xl" title='website Edit' onClick={() => navigate(`/add-page?website_id=${row.website_id}&page_id=${row.website_page_id}&type="edit"`)} />
            </div>)
         }

      },

   ]
   const [dataFilter, setDataFilter] = useState({
      start_date: "",
      end_date: "",
      status: "",
   })


   const websitePageState: any = useSelector((state: RootState) => state.websitepage)
   const {
      page,
      rowsPerPage,
      sortValue,
      searchValue,
      items,
      totalItems,
      isTableLoading,
      filterBy,
   } = websitePageState

 

   const { data, isFetching, isLoading } = useGetAllWebsitePageDataWithPaginationQuery({
      limit: rowsPerPage,
      searchValue: searchValue,
      page: page,
      website_id:queryValue,
      filterBy: filterBy,
      dateFilter: dataFilter,
   })
   
   useEffect(() => {
      if (!isFetching && !isLoading) {
         dispatch(setItems(data?.data || 0))
         dispatch(setTotalItems(data?.totalItem || 0))
         dispatch(setIsTableLoading(false))
      } else {
         dispatch(setIsTableLoading(true))
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isFetching, isLoading, data])

   return (
      <>
         <SingleWebsiteList
            headers={headers}
            rows={items}
            rowCount={totalItems}
            sortValue={sortValue}
            onSortValueChange={(newValue) => dispatch(setSortValue(newValue))}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={(newValue) => dispatch(setPage(newValue))}
            onRowsPerPageChange={(newValue) => dispatch(setRowsPerPage(newValue))}
            searchValue={searchValue}
            setSearchValue={(newValue) => dispatch(setSearchValue(newValue))}
            isTableLoading={isTableLoading}
            setDataFilter={setDataFilter}
            dataFilter={dataFilter}
         />
      </>
   )
}

export default SingleWebsiteInfoWrapper
