import React from 'react'
import { ImDatabase } from 'react-icons/im'
import ATMSkeleton from '../../components/UI/atoms/ATMSkeleton/ATMSkeleton'
import { useTopTenProductQuery } from '../../services/OrderServices'

const TopTenData = ({ startDate, endDate }: { startDate: string, endDate: string }) => {


    const todayOrderData = useTopTenProductQuery({ start_date: startDate, end_date: endDate })

    //here it is store of top ten data in card
    const todayOrderDataList = todayOrderData?.data?.data?.map((todayData: { product_name: string, count: string }, ind: number) => {
        return (

            <div key={todayData.product_name} className={`flex justify-between ${ind%2 ? "bg-red-50":""} py-3 px-5`}>
                <div className='p-1 col-span-2 ' key={ind}>{ind + 1}</div>
                <div className='p-1 col-span-5' key={todayData.product_name}>{todayData.product_name}</div>
                <div className='p-1 col-span-5 font-bold' key={todayData.count}>{todayData.count}</div>
            </div>
        )
    })


    return (
        <>
            <div className=' border-b-4 flex border-b-4 justify-between text-center  '>
                <h1 className='font-bold col-span-2 p-1'>
                    Sno.
                </h1>
                <h1 className='font-bold col-span-5 p-1'>
                    Product Name
                </h1>
                <h1 className='font-bold col-span-5 p-1'>
                    Total Order Count
                </h1>
            </div>
            <div className=' h-[700px] overflow-y-scroll  text-center items-center'>
                {(todayOrderDataList) ? todayOrderDataList.length ? <div className=''>{todayOrderDataList}</div> : (

                    
                        <div className={`flex flex-col justify-center col-span-12 items-center py-5`} >

                            <div className='text-4xl mb-2 text-primary-300' >
                                <ImDatabase />

                            </div>
                            <div className='text-slate-700'>
                                No Data Found
                            </div>
                            <div className='text-slate-400 text-sm'>
                                Sorry, we couldn't find any data.
                            </div>
                        </div>
                   

                ) :
                    (Array(10).fill(0).map((_, index) => (
                        <React.Fragment key={index}>
                            <div className='p-1 col-span-2 ' key={index + 'span+1'}><ATMSkeleton count={1} width="100%" height="100%" /></div>
                            <div className='p-1 col-span-5' key={index + 'span+5'}><ATMSkeleton count={1} width="100%" height="100%" /></div>
                            <div className='p-1 col-span-5' key={index + 'span+6'}><ATMSkeleton count={1} width="100%" height="100%" /></div>
                        </React.Fragment>
                    )))

                }
            </div>

        </>
    )
}


export default TopTenData