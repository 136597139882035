import React from 'react';
import { Provider } from 'react-redux';
import Navigation from './navigation/Navigation';
import store from './redux/store';
import { Toaster } from 'react-hot-toast';
import ATMLoader from './components/UI/atoms/ATMLoader/ATMLoader';
// import ListComponent from './screens/ListComponent';


function App() {
  return (
    <>
      <Provider store={store}>
        <ATMLoader />
        {/* <ListComponent/> */}
        <Navigation />
        <Toaster
          position='top-center'
          reverseOrder={false}
        />
      </Provider>
    </>


  );
}

export default App;
