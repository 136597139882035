import React from 'react'
import { TextField, InputAdornment } from '@mui/material'
import { ATMInputPropType } from './ATMInputPropType'
import { ErrorMessage } from 'formik'


const ATMInput = ({
    size = "medium",
    placeholder = "",
    variant = "outlined",
    extraClasses = "",
    onChange = () => { },
    name,
    value,
    required = false,
    inputType = "text",
    label,
    isValid = false,
    errorMessage,
    disabled = false,
    innerText = "",
    readOnly = false,
    autofocus = false,
}: ATMInputPropType) => {

    return (
        <div className='relative' >

            <TextField
                autoComplete="off"
                size={size}
                placeholder={placeholder}
                variant={variant}
                sx={{ width: "100%" }}
                className={`${extraClasses} `}
                onChange={(e) => onChange(e)}
                label={label}
                name={name}
                value={value}
                type={inputType}
                disabled={disabled ? true : false}
                inputProps={{
                    readOnly: readOnly,
                    autoFocus: autofocus,
                    endadornment: <InputAdornment position="end">{innerText}</InputAdornment>,
                    style: { fontSize: "14px", fontFamily: "poppins" }
                }}
                InputLabelProps={{
                    style: { fontSize: "14px", fontFamily: "poppins" }
                }}
            />
            <ErrorMessage name={name}  >
                {
                    (errMsg) => (
                        <p className="font-poppins absolute  text-normal-txt text-start mt-0 text-primary-500"> {errMsg} </p>
                    )
                }
            </ErrorMessage>
        </div>
    )
}
export default ATMInput
