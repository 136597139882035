import React, { useEffect } from "react";
import { LoginAPiDataType } from "../../models/auth.model";
import { object } from "yup";
import { Form, Formik } from "formik";
import { Box } from "@mui/material";
import OnboardingTemplate from "../../components/UI/templates/OnboardingTemplate/OnboardingTemplate";
import LoginForm from "./LoginForm";
import Logo from "../../images/telemartLogo.png";
import { isValidPassword } from "../../utils/constant/validation";
import { useSignInMutation } from "../../services/AuthServices";
import { useDispatch } from "react-redux";
import { setAuth } from "../../redux/slices/AuthSlice";
import showToast from "../../utils/toast";
import { useNavigate } from "react-router-dom";

const LoginFormWrapper = () => {
  const [signIn, signInInfo] = useSignInMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.getItem("authToken") && navigate("/dashboard-count");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValue: LoginAPiDataType = {
    username: "",
    password: "",
  };
  const validationSchema = object({
    // username: ,
    password: isValidPassword().required("Password field can not be blank."),
  });
  const handleFormSubmit = (values: any) => {
    signIn({
      username: values.username,
      password: values.password,
    }).then((res: any) => {
      if (res.data.status) {
        showToast(res?.data?.message, "success");
        localStorage.setItem("authToken", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.data));
        dispatch(
          setAuth({
            token: res.data.token,
            user: res.data.data,
          })
        );
        window.location.href = "/dashboard-count";
      } else {
        if (res?.error) {
          showToast(res?.error?.data?.message, "error");
        } else {
          showToast(res?.data?.message, "error");
        }
      }
    });
  };
  return (
    <>
      <OnboardingTemplate>
        <Box sx={{ pt: 5 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="text-xl fade font-bold mb-5">Sign in to</div>
            <img src={Logo} alt="logo" style={{ width: "45%" }} />
          </Box>
          <Box sx={{ pt: 2, textAlign: "center" }}>
            <Formik
              initialValues={initialValue}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleFormSubmit(values);
              }}
            >
              {(props) => (
                <Form className="mt-5">
                  <LoginForm props={props} loading={signInInfo.isLoading} />
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </OnboardingTemplate>
    </>
  );
};

export default LoginFormWrapper;
