import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from "../utils/constant";
const authToken = localStorage.getItem('authToken') || ""
export const adminApi = createApi({
    reducerPath: "adminApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/admin/` }),
    endpoints: (builder) => ({
         // Call Center
         getAllCenter: builder.query({
            query: () => ({
                url: "callcenter",
                headers: {
                    'x-access-token': authToken
                },
                method: "GET",
            })
        }),
    })
})

export const { useGetAllCenterQuery } = adminApi