export const REG_NUMBER = new RegExp(/^[0-9]*$/);
export const regName = RegExp(/^[a-z A-Z-.']+$/);
export const regAlpha = RegExp(/^[a-z A-Z]+$/);
export const regAlphaNum = RegExp(/^[a-z A-Z. 0-9',-]+$/);
export const REG_EMAIL = RegExp(/^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i);
export const regCard = RegExp(/^[0-9.]*$/);
export const regUrl = RegExp(
  /(http(s)?:\\)?([\w-]+\.)+[\w-]+[.com|.in|.org]+(\[\?%&=]*)?/
);
export const regPhone = RegExp(/^[0-9 \s]{10}$/);
export const regIndiaPhone = RegExp(/^[0]?[6789]\d{9}$/);
export const regnotnumeric = RegExp(
  /^[a-z A-Z !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/
);
export const regJoinDate = RegExp(
  /^(0[1-9]|[12][0-9]|3[01])[- /.](?:(0[1-9]|1[012])[- /.](19|20)[0-9]{2})$/
);
export const regHour = RegExp(/^\d{1,2}[:][0-5][0-9]$/);
export const regPin = RegExp(/^[1-9]\d{5}$/);
export const regPassword = RegExp(
  /^(?!.*[\s])(?=.*[A-Za-z])(?!.*[\s])(?=.*[0-9])(?!.*[\s]).{6,21}$/
);

export const imageMimetype = [
  "image/bmp",
  "application/postscript",
  "image/jpg",
  "image/jpe",
  "image/x-jp2",
  "image/x-jpf",
  "application/x-indesign",
  "image/heif",
  "image/x-panasonic-raw",
  "image/cis-cod",
  "image/vnd.adobe.photoshop",
  "image/gif",
  "image/ief",
  "image/jpeg",
  "image/png",
  "image/pipeg",
  "image/svg+xml",
  "image/tiff",
  "image/x-cmu-raster",
  "image/x-cmx",
  "image/x-icon",
  "image/x-portable-anymap",
  "image/x-portable-bitmap",
  "image/x-portable-graymap",
  "image/x-portable-pixmap",
  "image/x-rgb",
  "image/x-xbitmap",
  "image/x-xpixmap",
  "image/x-xwindowdump",
  "image/webp",
  "image/jfif",
];
export const videoMimetype = [
  "video/mp4",
  "application/mp4",
  "video/x-flv",
  "application/x-mpegURL",
  "video/MP2T",
  "video/3gpp",
  "video/quicktime",
  "video/x-msvideo",
  "video/x-ms-wmv",
  "video/webm",
];

export const documentMimeType = [
  "application/msword",
  "application/vnd.ms-word.document.macroEnabled.12",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  "application/vnd.ms-word.template.macroEnabled.12",
  "text/html",
  "application/pdf",
  "application/vnd.ms-powerpoint.template.macroEnabled.12",
  "application/vnd.openxmlformats-officedocument.presentationml.template",
  "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  "application/vnd.ms-powerpoint",
  "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/rtf",
  "text/rtf",
  "text/plain",
];

export const allMimetype = [
  "image/bmp",
  "application/postscript",
  "image/jpg",
  "image/jpe",
  "image/x-jp2",
  "image/x-jpf",
  "application/x-indesign",
  "image/heif",
  "image/x-panasonic-raw",
  "image/cis-cod",
  "image/vnd.adobe.photoshop",
  "image/gif",
  "image/ief",
  "image/jpeg",
  "image/png",
  "image/pipeg",
  "image/svg+xml",
  "image/tiff",
  "image/x-cmu-raster",
  "image/x-cmx",
  "image/x-icon",
  "image/x-portable-anymap",
  "image/x-portable-bitmap",
  "image/x-portable-graymap",
  "image/x-portable-pixmap",
  "image/x-rgb",
  "image/x-xbitmap",
  "image/x-xpixmap",
  "image/x-xwindowdump",
  "image/webp",
  "image/jfif",
  "video/mp4",
  "application/mp4",
  "video/x-flv",
  "application/x-mpegURL",
  "video/MP2T",
  "video/3gpp",
  "video/quicktime",
  "video/x-msvideo",
  "video/x-ms-wmv",
  "video/webm",
  "application/msword",
  "application/vnd.ms-word.document.macroEnabled.12",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  "application/vnd.ms-word.template.macroEnabled.12",
  "text/html",
  "application/pdf",
  "application/vnd.ms-powerpoint.template.macroEnabled.12",
  "application/vnd.openxmlformats-officedocument.presentationml.template",
  "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  "application/vnd.ms-powerpoint",
  "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/rtf",
  "text/rtf",
  "text/plain",
]