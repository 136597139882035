import { configureStore } from '@reduxjs/toolkit'
import { authSlice, loaderSlice } from './slices'
import { authApi } from '../services/AuthServices'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import OrderSlices from './slices/OrderSlices'
import { orderApi } from '../services/OrderServices'
import websiteSlice from './slices/websiteSlice'
import { websiteApi } from '../services/WebsiteService'
import { websitePageApi } from '../services/websitePageService'
import websitePageSlice from './slices/websitePageSlice'
import { adminApi } from '../services/AdminServices'
import BlogSlice from './slices/BlogSlice'
import { websiteBlogApi } from '../services/webSiteBlog'

const authMiddelware = () => (next: any) => (action: any) => {

  if (action.type.includes("rejected") && action.payload?.status === 401) {
    localStorage.clear()
    window.location.href = "/"

  }
  if (action?.payload?.originalStatus === 403 && action.type.includes("rejected")) {
    // localStorage.clear()
    window.location.href = "/access"

  }
  return next(action)

}

const store = configureStore({
  reducer: {
    auth: authSlice,
    loader: loaderSlice,
    order: OrderSlices,
    website:websiteSlice,
    websitepage:websitePageSlice,
    blog:BlogSlice,
    [websiteBlogApi.reducerPath]:websiteBlogApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [websiteApi.reducerPath]: websiteApi.reducer,
    [websitePageApi.reducerPath]:websitePageApi.reducer,
    [adminApi.reducerPath]:adminApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat([
        authMiddelware,
        authApi.middleware,
        orderApi.middleware,
        websiteApi.middleware,
        websitePageApi.middleware,
        adminApi.middleware,
        websiteBlogApi.middleware,
      ]),
})

setupListeners(store.dispatch)
export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;