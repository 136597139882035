import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiDataWithPaginationTypes } from '../models/commonModel';
import { BASE_URL } from "../utils/constant";

const authToken = localStorage.getItem('authToken') || ""

export const orderApi = createApi({
    reducerPath: "orderApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/order` }),
    endpoints: (builder) => ({

        // With Pagination
        getOrdersWithPagination: builder.query({
            query: (body: ApiDataWithPaginationTypes) => ({
                url: "",
                headers: {
                    'x-access-token': authToken
                },
                method: "POST",
                body,
            })
        }),

        // OERDER COUNT
        getOrderCount: builder.query({
            query: () => ({
                url: "order-countsssss",
                headers: {
                    'x-access-token': authToken
                },
                method: "GET",
            })
        }),

        // **** GET BY ID
        getOrderById: builder.query({
            query: (id) => ({
                url: `view/${id}`,
                headers: {
                    "x-access-token": authToken,
                },
                method: "GET",

            }),
        }),

        // *** update order
        UpdateOrderById: builder.mutation({
            query: ({ id, body }) => ({
                url: `order-update/${id}`,
                headers: {
                    "x-access-token": authToken,
                },
                method: "PUT",
                body,

            }),
        }),

        getOrderToday: builder.query({
            query: ({ start_date, end_date }) => ({
                url: `/today-product`,
                params: { start_date, end_date },
                headers: {
                    "x-access-token": authToken,
                },
                method: "GET",

            }),
        }),
        getOrderTodayCount: builder.query({
            query: ({ start_date, end_date }) => ({
                url: `/today-product-count`,
                params: { start_date, end_date },
                headers: {
                    "x-access-token": authToken,
                },
                method: "GET",

            }),
        }),
        UpdateOrder: builder.mutation({
            query: ({ id, body }) => ({
                url: `update/${id}`,
                headers: {
                    "x-access-token": authToken,
                },
                method: "PUT",
                body,

            }),
        }),
        TopTenProduct: builder.query({
            query: ({ start_date, end_date }: { start_date: string, end_date: string }) => ({
                url: `/topTenProducts`,
                params: { start_date, end_date },
                headers: {
                    "x-access-token": authToken,
                },
                method: "GET",

            }),
        }),
        allProduct: builder.query({
            query: () => ({
                url: `/all-product`,
                headers: {
                    "x-access-token": authToken,
                },
                method: "GET",

            }),
        }),
    })
})

export const { useTopTenProductQuery, useUpdateOrderMutation, useUpdateOrderByIdMutation ,useGetOrderTodayCountQuery
   , useGetOrdersWithPaginationQuery, useGetOrderCountQuery, useGetOrderByIdQuery, useGetOrderTodayQuery, useAllProductQuery } = orderApi
