import { Button, Paper, TextareaAutosize, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import SideNavLayout from "../../../components/layouts/sideNavLayout/SideNavLayout";
import { Formik } from "formik";
import showToast from "../../../utils/toast";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  useAddBlogDataMutation,
  useGetBlogDataQuery,
  useUpdateBlogDataMutation,
} from "../../../services/webSiteBlog";
// import from 'simple-xml-to-json';
// import showToast from '../../../utils/toast'
const AddBlog = () => {
  const { search } = useLocation();
  const [cancel, setCancel] = useState(false);
  const navigate = useNavigate();
  let websiteId = new URLSearchParams(search).get("website_id");
  let queryType = new URLSearchParams(search).get("type");
  let name = new URLSearchParams(search).get("name");
  let BlogID = new URLSearchParams(search).get("page_id");
  const [initialData, setInitialData] = useState({
    blog_name: "",
    blog_title: "",
    blog_description: "",
    blog_subtitle: "",
    blog_image: "",
    websiteId: websiteId,
  });

  const [updateBlogData, updateBlogDataInfo] = useUpdateBlogDataMutation();
  const { data, isFetching, isLoading } = useGetBlogDataQuery(
    { id: BlogID },
    { skip: !BlogID }
  );
  const [addWebsiteData, addWebsiteDataInfo] = useAddBlogDataMutation();

  useEffect(() => {
    if (!addWebsiteDataInfo.isLoading && addWebsiteDataInfo.isSuccess) {
      if (addWebsiteDataInfo?.data?.status) {
        showToast(addWebsiteDataInfo.data.msg, "success");
        // window.location.pathname = '/website'
      } else {
        showToast(addWebsiteDataInfo.data.msg, "error");
      }
    }
  }, [addWebsiteDataInfo]);
  useEffect(() => {
    if (!updateBlogDataInfo.isLoading && updateBlogDataInfo.isSuccess) {
      if (updateBlogDataInfo?.data?.status) {
        showToast(updateBlogDataInfo.data.msg, "success");
        // window.location.pathname = '/website'
      }
    }
  }, [updateBlogDataInfo]);
  useEffect(() => {
    if (!isLoading && !isFetching && data) {
      let initialData = {
        blog_name: data?.data.blog_name,
        blog_title: data?.data.blog_title,
        blog_description: data?.data.blog_description,
        blog_subtitle: data?.data.blog_subtitle,
        blog_image: data?.data.blog_image,
        websiteId: data?.data.website_id,
      };
      setInitialData(initialData);
    }
  }, [isLoading, isFetching, data]);
  return (
    <SideNavLayout>
      <div className="w-full px-2 py-2 mt-3  h-full overflow-auto">
        <Paper elevation={3}>
          <div className="flex py-2 items-center justify-between ">
            <h1 className="mx-5 font-bold ">
              {" "}
              {queryType ? "Edit " : " Add"} Blog ({name})
            </h1>
            <button
              className=" pr-5 text-red-700"
              onClick={() => setCancel(true)}
            >
              {" "}
              X
            </button>
            {cancel && (
              <Navigate
                to={`/blog-view?search=${websiteId}&&name=${name}`}
                replace={true}
              />
            )}
          </div>
          {
            <Formik
              enableReinitialize={true}
              initialValues={{
                blog_name: initialData.blog_name,
                blog_title: initialData.blog_title,
                blog_description: initialData.blog_description,
                blog_subtitle: initialData.blog_subtitle,
                blog_image: initialData.blog_image,
                website_id: websiteId,
              }}
              validationSchema={yup.object().shape({
                sitemap_url: yup
                  .string()
                  .trim()
                  // eslint-disable-next-line  no-useless-escape
                  .matches(
                    /^[<]urlset\b[A-za-z0-9=*/\;:."'-_]*/,
                    "Is not in correct format"
                  ),
              })}
              onSubmit={(values: any) => {
                // let parser = new DOMParser()
                // let xmlDoc = parser.parseFromString(values.sitemap_url, 'text/xml')

                if (queryType) {
                  updateBlogData({ body: values, id: BlogID });
                  navigate(`/blog-view?search=${websiteId}&name=${name}`);
             
                  return;
                }
                // values = { ...values, ["blog_image"]: imageFile };
                addWebsiteData(values);
                navigate(`/blog-view?search=${websiteId}&name=${name}`);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="h-auto  py-4 px-3 items-center justify-start pl-5 ">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-6">
                        <label
                          className="font-bold text-md"
                          htmlFor="bootstrap-input"
                        >
                          Blog Name
                        </label>
                        <TextField
                          size="medium"
                          type="text"
                          onChange={handleChange}
                          name="blog_name"
                          value={values.blog_name}
                          fullWidth
                          required
                        />
                      </div>

                      <div className="col-span-6">
                        <label
                          className="font-bold text-md"
                          htmlFor="bootstrap-input"
                        >
                          Blog title
                        </label>
                        <TextField
                          size="medium"
                          type="text"
                          onChange={handleChange}
                          name="blog_title"
                          value={values.blog_title}
                          fullWidth
                          required
                        />
                      </div>
                      <div className="col-span-6">
                        <label
                          className="font-bold text-md"
                          htmlFor="bootstrap-input"
                        >
                          Blog Subtitle
                        </label>
                        <TextField
                          size="medium"
                          type="text"
                          onChange={handleChange}
                          name="blog_subtitle"
                          value={values.blog_subtitle}
                          fullWidth
                          required
                        />
                      </div>

                      {/* <div className="col-span-6">
                        <label
                          className="font-bold text-md"
                          htmlFor="bootstrap-input"
                        >
                          image
                        </label>
                        <TextField
                          size="medium"
                          type="file"
                          onChange={(e: React.FormEvent) => {
                            const files = (e.target as HTMLInputElement).files;

                            if (files && files.length > 0) {
                              setfile(files[0]);
                            }
                            // setfile(e.target.files[0]);
                          }}
                          name="blog_image"
                          fullWidth
                        />
                      </div> */}
                      <div className="col-span-6">
                        <label
                          className="font-bold text-md"
                          htmlFor="bootstrap-input"
                        >
                          image
                        </label>
                        <TextField
                          size="medium"
                          type="text"
                          onChange={handleChange}
                          name="blog_image"
                          value={values.blog_image}
                          fullWidth
                        />
                      </div>

                      <div className="col-span-12">
                        <label
                          className="font-bold text-md"
                          htmlFor="bootstrap-input"
                        >
                          blog description
                        </label>
                        <TextareaAutosize
                          aria-label="minimum height"
                          minRows={3}
                          className="w-full  border-slate-200 border-2 rounded-md"
                          // placeholder="Minimum 3 rows"
                          onChange={handleChange}
                          name="blog_description"
                          value={values.blog_description}
                          // required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex py-6 mx-4 items-end justify-end">
                    <Button type="submit" variant="contained">
                      Submit
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          }
        </Paper>
        <hr />
      </div>
    </SideNavLayout>
  );
};

export default AddBlog;
