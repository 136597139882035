import { Paper } from '@mui/material'
import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'
import SideNavLayout from '../../../components/layouts/sideNavLayout/SideNavLayout'
import { ATMPagination } from '../../../components/UI/atoms'
import MOLTable from '../../../components/UI/molecules/MOLTable/MOLTable'
import { headersTypes } from '../../../components/UI/molecules/MOLTable/MOLTablePropTypes'
interface WebsiteListingPropTypes {
    headers: headersTypes[],
    rows: any[],
    rowCount?: number;
    sortValue: { field: string, value: 'DESC' | 'ASC' },
    onSortValueChange: (newValue: { field: string; value: 'DESC' | 'ASC' }) => void;
    page?: number;
    rowsPerPage?: number;
    onPageChange?: (newValue: number) => void;
    onRowsPerPageChange?: (newValue: number) => void;
    searchValue?: string;
    setSearchValue?: (newValue: string) => void;
    onRowClick?: (row: any) => void;
    isTableLoading?: boolean;

    handleFileChange?: any,
    setDataFilter?: React.Dispatch<React.SetStateAction<{ start_date: string; end_date: string; status: string; }>>
    dataFilter?: { start_date: string; end_date: string; status: string; },

}
const WebsiteList = ({
    headers,
    rows,
    rowCount = 0,
    sortValue,
    onSortValueChange,
    page = 1,
    rowsPerPage = 5,
    onPageChange = () => { },
    onRowsPerPageChange = () => { },
    searchValue = "",
    setSearchValue = () => { },
    onRowClick,
    isTableLoading,
    setDataFilter = () => { },
    dataFilter,
}: WebsiteListingPropTypes) => {
    const [isAddWebsite, setIsAddWebsite] = useState(false)
    return (
        <SideNavLayout>
            <div className='w-full p-3  h-full overflow-auto' >
                <div className='flex items-center justify-between'>
                    <div className='col-start-1'>
                        <div className='text-slate-700 text-lg  '  > Website </div>
                        <div className='text-xs text-slate-500 ' > Manage your all Websites here </div>
                    </div>
                    <div className=''>
                        <button className='bg-red-400 rounded px-3 py-1 text-white' onClick={() => setIsAddWebsite(true)}>Add website</button>
                    </div>

                </div>
                <Paper className='px-2 py-2 mt-3' elevation={3} >
                    <div className='grid grid-cols-12 my-3 rounded gap-2 ' >

                        {/* Search Box */}
                        <div className='col-span-2' >
                            <input
                                type="text"
                                value={searchValue}
                                onChange={(e) => { setSearchValue(e.target.value) }}
                                placeholder="Search for orders..."
                                className='border border-slate-300 rounded text-sm text-slate-500 py-1 px-2  outline-slate-300 w-full '
                            />
                        </div>



                    </div>
                    {/* Table */}
                    <div className='border rounded border-slate-100 max-h-[70vh] overflow-scroll scroll-smooth' id="scroll-top">
                        <MOLTable
                            headers={headers}
                            rows={rows}
                            sortValue={sortValue}
                            onSortValueChange={onSortValueChange}
                            onRowClick={onRowClick}
                            rowsPerPage={rowsPerPage}
                            isLoading={isTableLoading}
                        />
                    </div>

                    {/* Pagination */}
                    <div className='mt-2 py-2' >
                        <ATMPagination
                            page={page}
                            onPageChange={(newValue) => onPageChange(newValue)}
                            rowCount={rowCount}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={(newPage => onRowsPerPageChange(newPage))}
                            rows={rows}
                        />
                    </div>

                    {isAddWebsite && (
                        <Navigate to="/add-website" replace={true} />
                    )}
                </Paper>
            </div>
        </SideNavLayout>
    )
}

export default WebsiteList