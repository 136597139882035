

import { Button, Paper, TextField } from '@mui/material'
import React, { useState, useEffect } from 'react'
import SideNavLayout from '../../../components/layouts/sideNavLayout/SideNavLayout'
// import { useGetOrderByIdQuery, useUpdateOrderByIdMutation } from '../../../services/OrderServices'
import { Formik } from 'formik'
import showToast from '../../../utils/toast'
import { Navigate, useLocation } from 'react-router-dom'
import { useAddWebsitePageMetaDataMutation } from '../../../services/websitePageService'

const AddPageHeadInfo = () => {
  const { search } = useLocation()
  
  let website_id = new URLSearchParams(search).get('website_id')
  let website_page_id = new URLSearchParams(search).get('website_page_id')
  const [setAddMeta, AddMetaInfo] = useAddWebsitePageMetaDataMutation()

  let initialData = {
    'website_id': "",
    'website_page_id': "",
    'meta_keyword': "",
    'meta_description': "",
    'meta_og_title': "",
    'meta_og_url': "",
    'meta_og_image': "",
    'meta_og_description': "",
    'meta_og_type': "",
    'meta_twitter_card': "",
    'meta_twitter_image': "",
    'meta_twitter_title': "",
    "canonical_url":"",

  }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ordMetaData, setMetaData] = useState(initialData)
  const [cancel, setCancel] = useState(false)



  useEffect(() => {
    if (!AddMetaInfo.isLoading && !AddMetaInfo.isLoading && !AddMetaInfo.isUninitialized) {
      if (AddMetaInfo?.data?.status) {

        showToast(AddMetaInfo?.data?.msg, "success")
        
      } else {
        showToast(AddMetaInfo?.data?.msg, "error")
      }
    }

  }, [AddMetaInfo])

 

  return (
    <SideNavLayout>
      <div className='w-full px-2 py-2 mt-3  h-full overflow-auto'>
        <Paper elevation={3}  >


          <div className='flex py-2 items-center justify-between'>
            <h1 className='mx-5 font-bold'>Meta Info</h1>
            <button className=' pr-5 text-red-700' onClick={() => setCancel(true)}> X</button>
              {cancel && (
                <Navigate to={`/website-view?search=${website_id}`} replace={true} />
              )}
          </div>

          {
            <Formik
              enableReinitialize={true}
              initialValues={{

                'website_id': website_id,
                'website_page_id':website_page_id,
                'meta_keyword': "",
                'meta_description': "",
                'meta_og_title': "",
                'meta_og_url': "",
                'meta_og_image': "",
                'meta_og_description': "",
                'meta_og_type': "",
                'meta_twitter_card': "",
                'meta_twitter_image': "",
                'meta_twitter_title': "",
                "canonical_url":""
              }}

              onSubmit={(values: any) => {
                setAddMeta(values)


              }}

            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue
                /* and other goodies */
              }) => (

                <form onSubmit={handleSubmit}>
                  <div className='h-auto  py-4 px-3 items-center justify-start pl-5 '>
                    <div className='grid grid-cols-12 gap-6'>

                      <div className='col-span-6'>
                        <label className="font-bold text-md" htmlFor="bootstrap-input">
                          meta keyword
                        </label>
                        <TextField
                          size='medium'
                          type='text'
                          onChange={handleChange}
                          name='meta_keyword'
                          value={values.meta_keyword}
                          fullWidth
                          required

                        />
                      </div>

                      <div className='col-span-6'>
                        <label className="font-bold text-md" htmlFor="bootstrap-input">
                          meta description
                        </label>
                        <TextField
                          size='medium'
                          type='text'
                          value={values.meta_description}
                          onChange={handleChange}
                          name='meta_description'
                          fullWidth
                          required

                        />
                      </div>
                      <div className='col-span-6'>
                        <label className="font-bold text-md" htmlFor="bootstrap-input">
                          meta og title
                        </label>
                        <TextField
                          size='medium'
                          type='text'
                          onChange={handleChange}
                          name='meta_og_title'
                          value={values.meta_og_title}
                          fullWidth
                          required

                        />
                      </div>
                      <div className='col-span-6'>
                        <label className="font-bold text-md" htmlFor="bootstrap-input">
                          meta og description
                        </label>
                        <TextField
                          size='medium'
                          type='text'
                          onChange={handleChange}
                          name='meta_og_description'
                          value={values.meta_og_description}
                          fullWidth
                          required

                        />
                      </div>
                      <div className='col-span-6'>
                        <label className="font-bold text-md" htmlFor="bootstrap-input">
                          meta og type
                        </label>
                        <TextField
                          size='medium'
                          type='text'
                          onChange={handleChange}
                          name='meta_og_type'
                          value={values.meta_og_type}
                          fullWidth
                          required

                        />
                      </div>
                      <div className='col-span-6'>
                        <label className="font-bold text-md" htmlFor="bootstrap-input">
                          meta og url
                        </label>
                        <TextField
                          size='medium'
                          type='text'
                          onChange={handleChange}
                          name='meta_og_url'
                          value={values.meta_og_url}
                          fullWidth
                          required

                        />
                      </div>
                      <div className='col-span-6'>
                        <label className="font-bold text-md" htmlFor="bootstrap-input">
                          meta og image
                        </label>
                        <TextField
                          size='medium'
                          type='text'
                          onChange={handleChange}
                          name='meta_og_image'
                          value={values.meta_og_image}
                          fullWidth
                          required

                        />
                      </div>
                      <div className='col-span-6'>
                        <label className="font-bold text-md" htmlFor="bootstrap-input">
                          twitter  card
                        </label>
                        <TextField
                          size='medium'
                          type='text'
                          onChange={handleChange}
                          name='meta_twitter_card'
                          value={values.meta_twitter_card}
                          fullWidth
                          required

                        />
                      </div>
                      <div className='col-span-6'>
                        <label className="font-bold text-md" htmlFor="bootstrap-input">
                          twitter
                        </label>
                        <TextField
                          size='medium'
                          type='text'
                          onChange={handleChange}
                          name='meta_twitter_title'
                          value={values.meta_twitter_title}
                          fullWidth
                          required

                        />
                      </div>
                      <div className='col-span-6'>
                        <label className="font-bold text-md" htmlFor="bootstrap-input">
                          twitter image
                        </label>
                        <TextField
                          size='medium'
                          type='text'
                          multiline
                          // rows={2}
                          onChange={handleChange}
                          name='meta_twitter_image'
                          value={values.meta_twitter_image}
                          fullWidth
                          required

                        />

                      </div>
                      <div className="col-span-6">
                        <label
                          className="font-bold text-md"
                          htmlFor="bootstrap-input"
                        >
                          Canonical Url
                        </label>
                        <TextField
                          size="medium"
                          type="text"
                          multiline
                          // rows={2}
                          onChange={handleChange}
                          name="canonical_url"
                          value={values.canonical_url}
                          fullWidth
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className='flex py-6 mx-4 items-end justify-end'>
                    <Button
                      type='submit'
                      variant='contained'
                    >
                      submit
                    </Button>
                  </div>
                </form>)
              }
            </Formik>}
        </Paper>
        <hr />


      </div >
    </SideNavLayout >
  )
}

export default AddPageHeadInfo