import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Logo from "../../../images/telemartLogo.png";
import { TiThMenu } from "react-icons/ti";
import { TbLayoutSidebarLeftCollapse } from "react-icons/tb";
import {
  MdShoppingCart,
  MdPeople,
  MdNotifications,
  MdOutlineGrid4X4,
  MdInsertDriveFile,
} from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileMenuCard from "./ProfileMenuCard";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { FormatTextdirectionLToRSharp } from "@mui/icons-material";

const navItems = [
  // {
  //   label: "Dashboard(Real*)",
  //   icon: <MdPeople />,
  //   path: "/dashboard-count",
  // },
  {
    label: "Dashboard",
    icon: <MdPeople />,
    path: "/dashboard",
  },
  {
    label: "Analytics",
    icon: <MdOutlineGrid4X4 />,
    path: "/analytics",
  },
  {
    label: "Orders",
    icon: <MdShoppingCart />,
    path: "/orders",
  },
  {
    label: "Agent",
    icon: <SupportAgentIcon />,
    path: "/agent",
  },
  {
    label: "Website",
    icon: <FormatTextdirectionLToRSharp />,
    path: "/website",
  },
  // {
  //     label: 'File Mangeer',
  //     icon: <MdInsertDriveFile/>,
  //     path: "/file-manager"
  // },
  {
    label: "Blog",
    icon: <MdInsertDriveFile />,
    path: "/blog",
  },
];

interface SideNavLayoutPropTypes {
  children: React.ReactNode;
}

const SideNavLayout = ({ children }: SideNavLayoutPropTypes) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [fade, setFade] = useState(true);
  const [isOpenProfileMenu, setIsOpenProfileMenu] = useState(false);

  return (
    <div className="w-screen relative h-[100vh]">
      {/* Header */}
      <div className="sticky top-0 left-0 right-0 py-2 z-[999]  px-3 border-b bg-white border-primary-200 flex items-center justify-between">
        <div className="flex gap-8">
          <div
            onClick={() => {
              setIsNavExpanded((isNavExpanded) => !isNavExpanded);
              setFade(true);
            }}
            className="text-2xl text-primary-500 cursor-pointer "
          >
            {isNavExpanded ? <TbLayoutSidebarLeftCollapse /> : <TiThMenu />}
          </div>
          <div>
            {" "}
            <img src={Logo} alt="" width="30%" />{" "}
          </div>
        </div>

        <div className="flex gap-8 items-center pr-2">
          <div className="text-2xl text-primary-500">
            {" "}
            <MdNotifications />{" "}
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              setIsOpenProfileMenu((isOpenProfileMenu) => !isOpenProfileMenu);
            }}
          >
            {" "}
            <Avatar
              sx={{ width: 30, height: 30, fontSize: 14, bgcolor: "#646ae2" }}
              className="font-bold"
            >
              {" "}
              {JSON.parse(localStorage.getItem("user") || "{}")?.username &&
                JSON.parse(
                  localStorage.getItem("user") || "{}"
                ).username[0].toUpperCase()}{" "}
            </Avatar>{" "}
          </div>
        </div>

        {isOpenProfileMenu && (
          <ProfileMenuCard onClickAway={() => setIsOpenProfileMenu(false)} />
        )}
      </div>

      {/* Main */}
      <div className="relative flex">
        {/* Side Nav */}
        <div
          className={`sticky top-0 left-0 h-[94vh]  border-r border-primary-100 py-2 ${
            isNavExpanded ? "expand" : "collapse"
          }`}
        >
          {navItems.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => navigate(item.path)}
                className={`flex gap-5 py-2 px-4 mb-1 items-center cursor-pointer ${
                  `/${location.pathname.split("/")[1]}` === item.path &&
                  "border-r-4 border-primary-400 rounded-sm text-primary-500 bg-primary-50 "
                } `}
              >
                <div className="text-xl text-primary-500"> {item.icon} </div>
                {isNavExpanded && (
                  <div className={`text-lg ${fade && "opacity-max"}`}>
                    {item.label}
                  </div>
                )}
              </div>
            );
          })}
        </div>

        {/* Page */}
        <div
          className={`h-[94vh] relative ${
            isNavExpanded ? "main-page-collapse" : "main-page-expand"
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default SideNavLayout;
