import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AllOrdersDataType } from "../../models/order.model";


export interface OrderSliceStateType {
  items: AllOrdersDataType[] | [];
  totalItems: number;
  isTableLoading: boolean;
  page: number;
  rowsPerPage: number;
  searchValue: string;
  sortValue: { field: string; value: "DESC" | "ASC" };
  filterBy: { fieldName: string; value: string[] | number[] }[];
  dataFilter: {
    start_date: any;
    end_date: any;
    status: string;
    idtag?:string;
  };
  dataDashboardFilter: {
    start_date: any;
    end_date: any;
    status: string;
    idtag?:string;
  };
}

const initialState: OrderSliceStateType = {
  items: [],
  totalItems: 0,
  isTableLoading: false,
  page: 1,
  rowsPerPage: 10,
  searchValue: "",
  sortValue: { field: "dealerCode", value: "ASC" },
  filterBy: [
    {
      fieldName: "mode",
      value: [],
    },
  ],
  dataDashboardFilter: {
    start_date: '',
    end_date: '',
    status: "",
    idtag:''
  },
  dataFilter: {
    start_date: '',
    end_date: '',
    status: "",
    idtag:''
  },
};

const orderSlice: any = createSlice({
  name: "order",
  initialState,
  reducers: {
    setItems: (state, action: PayloadAction<AllOrdersDataType[] | []>) => {
      state.items = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setRowsPerPage: (state, action: PayloadAction<number>) => {
      state.rowsPerPage = action.payload;
      state.page = 1;
      document.getElementById("scroll-top")?.scrollTo(0, 0);
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
      state.page = 1;
    },
    setSortValue: (
      state,
      action: PayloadAction<{ field: string; value: "DESC" | "ASC" }>
    ) => {
      state.sortValue = action.payload;
    },
    setTotalItems: (state, action: PayloadAction<number>) => {
      state.totalItems = action.payload;
    },
    setIsTableLoading: (state, action: PayloadAction<boolean>) => {
      state.isTableLoading = action.payload;
    },
    setFilterBy: (
      state,
      action: PayloadAction<{ fieldName: string; value: string[] | number[] }[]>
    ) => {
      state.filterBy = action.payload;
    },
    setDataFilter: (
      state,
      action: PayloadAction<{
        start_date: any;
        end_date: any;
        status: string;
        idtag:string;
      }>
    ) => {
      state.dataFilter = action.payload;
    },
    setDataDashboardFilter: (
      state,
      action: PayloadAction<{
        start_date: any;
        end_date: any;
        status: string;
        idtag?:string;
      }>
    ) => {
      state.dataDashboardFilter = action.payload;
    },
  },
});

export const {
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setSortValue,
  setTotalItems,
  setIsTableLoading,
  setFilterBy,
  setDataFilter,
  setDataDashboardFilter
} = orderSlice.actions;
export default orderSlice.reducer;
