import { Avatar, ClickAwayListener } from '@mui/material'
import React from 'react'
import { BiLogOutCircle } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

interface ProfileMenuCardPropTypes {
    onClickAway: () => void;
}

const ProfileMenuCard = ({
    onClickAway,
}: ProfileMenuCardPropTypes
) => {

    const navigate = useNavigate()

    const handleLogout = () => {
        localStorage.clear()
        navigate('/')
    }

    return (
        <>
            <ClickAwayListener onClickAway={() => onClickAway()} >

                <div className='absolute top-[58px] bg-white right-3 px-2 py-3 border border-slate-200 rounded shadow-xl w-[300px] animate-fade    ' >

                    <div>


                        <div className='flex justify-center mb-2' >
                            <Avatar sx={{ width: 40, height: 40, fontSize: 15, bgcolor: '#646ae2' }} className='font-bold'   > {JSON.parse(localStorage.getItem('user') || "{}")?.username[0]} </Avatar>
                        </div>

                        <div className='text-md mt-1 text-center'>
                          <p className='font-bold'> {JSON.parse(localStorage.getItem('user') || "{}")?.username}</p>
                        </div>

                        <div className='text-sm  text-slate-500 text-center border-b border-slate-200 pb-3'>
                        ({JSON.parse(localStorage.getItem('user') || "{}")?.role})
                        </div>

                        <div

                            onClick={() => { handleLogout() }}
                            className='cursor-pointer flex mt-2 '
                        >
                            <div className='flex items-center justify-center py-1 gap-2 text-sm text-slate-600 hover:bg-slate-200 hover:text-black w-full rounded' >

                                <BiLogOutCircle className='text-lg' />
                                Sign out
                            </div>
                        </div>
                    </div>
                </div>
            </ClickAwayListener>

        </>
    )
}

export default ProfileMenuCard
