
import { Routes, Route, BrowserRouter } from "react-router-dom";
import LoginFormWrapper from "../forms/Onboarding/LoginFormWrapper";
import AgentView from "../screens/agent/AgentView";
import Analytics from "../screens/analytics/Analytics";
import Dashboard from "../screens/dashboard/Dashboard";
import OrderListingWrapper from "../screens/orders/list/OrderListingWrapper";
import AddPage from "../screens/website-page/add/AddPage";
import AddPageHeadInfo from "../screens/website-page/view/AddPageHeadInfo";
import AddWebSite from "../screens/website/add/AddWebSite";
import WebsiteListingWrapper from "../screens/website/list/WebsiteListingWrapper";
import SingleWebsiteInfoWrapper from "../screens/website-page/list/SingleWebsiteInfoWrapper";
import EditWebsite from "../screens/website/edit/EditWebsite";
import FileManager from "../screens/filemanage/FileManager";
import BlogWebsiteListingWrapper from "../screens/blog/list/BlogWebsiteListingWrapper";
import BlogViewListingWrapper from "../screens/blog/view/BlogViewListingWrapper";
import AddBlog from "../screens/blog/add/AddBLog";
import AccessDenied from "../components/Permission/AccessDenied";
import DashboardCount from "../screens/RealDashbard/DashboardCount";

const Navigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/access' element={<AccessDenied/>} />
        <Route path="/" element={<LoginFormWrapper />} />
        <Route path="dashboard" element={<Dashboard/>} />
        <Route path="dashboard-count" element={<DashboardCount/>} />
        <Route path="analytics" element={<Analytics/>} />
        <Route path="orders" element={<OrderListingWrapper />} />
        <Route path="agent" element={<AgentView/>} />
        <Route path="website" element={<WebsiteListingWrapper/>} />
        <Route path="add-website" element={<AddWebSite/>} />
        <Route path="website-view" element={<SingleWebsiteInfoWrapper/>} />
        <Route path="add-page" element={<AddPage/>} />
        <Route path="website-page-view" element={<AddPageHeadInfo/>} />
        <Route path="edit-website" element={<EditWebsite/>} />
        <Route path="file-manager" element={<FileManager/>} />
        <Route path="blog" element={<BlogWebsiteListingWrapper/>} />
        <Route path="blog-view" element={<BlogViewListingWrapper/>} />
        <Route path="add-blog" element={<AddBlog/>} />
      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
