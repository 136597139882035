import { format } from 'date-fns'
import React from 'react'
import SideNavLayout from '../../components/layouts/sideNavLayout/SideNavLayout'
import TopTenData from './TopTenData'

const Analytics = () => {
    const date = format(new Date(), 'yyyy/MM/dd')
    return (
        <SideNavLayout>
            <div className='flex-col md:flex-row flex gap-5 h-[94vh] overflow-y-scroll p-5 '>

            <div className='md:h-full shadow-lg border-2 border-red-300 flex-1'>
                    <h2 className='font-bold text-center border-y-2 p-2 border-red-300 '>TODAY`S TOP PRODUCTS</h2>
                    <TopTenData startDate={date} endDate={date} key={"Today product data"} />
                </div>

                <div className='md:h-full  shadow-lg border-2 border-red-300 flex-1'>
                    <h2 className='font-bold text-center border-y-2 p-2 border-red-300 '>TOP 10 PRODUCTS (ALL TIME)</h2>
                    <TopTenData startDate={""} endDate={""} key={"All product data"} />
                </div>

               
            </div>


        </SideNavLayout>
    )
}


export default Analytics