
import { Button, Paper, TextareaAutosize, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SideNavLayout from '../../../components/layouts/sideNavLayout/SideNavLayout'
import { ErrorMessage, Formik } from 'formik'
import { useAddWebsiteDataMutation, useGetWebsiteDataQuery, useUpdateWebsiteDataMutation } from '../../../services/WebsiteService'
import showToast from '../../../utils/toast'
import { Navigate, useLocation } from 'react-router-dom'
import * as yup from 'yup';
// import from 'simple-xml-to-json';
// import showToast from '../../../utils/toast'
const AddWebsite
  = () => {
    const { search } = useLocation()
    const [cancel, setCancel] = useState(false)
    let queryId = new URLSearchParams(search).get('search')
    let queryType = new URLSearchParams(search).get('type')
    const [initialData, setInitialData] = useState({
      "product_name": "",
      "product_tag": "",
      "url": "",
      "sitemap_url": "",
      "header_space": "",
      "footer_space": "",
      "gatag_ip": "",
      "search_console_id": "",
      "conversin_tag": "",
    })

    const [updateWebsiteData, updateWebsiteDataInfo] = useUpdateWebsiteDataMutation()
    const { data, isFetching, isLoading } = useGetWebsiteDataQuery({ "id": queryId }, { skip: !queryId })
    const [addWebsiteData, addWebsiteDataInfo] = useAddWebsiteDataMutation()

    useEffect(() => {
      if (!addWebsiteDataInfo.isLoading && addWebsiteDataInfo.isSuccess) {
        if (addWebsiteDataInfo?.data?.status) {
          showToast(addWebsiteDataInfo.data.msg, "success")
          // window.location.pathname = '/website'
        }
        else {

          showToast(addWebsiteDataInfo.data.msg, "error")
        }
      }
    }, [addWebsiteDataInfo])
    useEffect(() => {
      if (!updateWebsiteDataInfo.isLoading && updateWebsiteDataInfo.isSuccess) {
        if (updateWebsiteDataInfo?.data?.status) {
          showToast(updateWebsiteDataInfo.data.msg, "success")
          // window.location.pathname = '/website'
        }
      }
    }, [updateWebsiteDataInfo])
    useEffect(() => {
      if (!isLoading && !isFetching && data) {
        let initialData = {
          "product_name": data?.data.product_name,
          "product_tag": data?.data.product_tag,
          "url": data?.data.url,
          "sitemap_url": data?.data.sitemap_url,
          "header_space": data?.data.header_space,
          "footer_space": data?.data.footer_space,
          "gatag_ip": data?.data.gatag_ip,
          "search_console_id": data?.data.search_console_id,
          "conversin_tag": data?.data.conversin_tag,
        }
        setInitialData(initialData)
      }
    }, [isLoading, isFetching, data])
    return (
      <SideNavLayout>
        <div className='w-full px-2 py-2 mt-3  h-full overflow-auto'>
          <Paper elevation={3}  >
            <div className='flex py-2 items-center justify-between '>
              <h1 className='mx-5 font-bold '> {queryType ? "Edit " : " Add"} Website</h1>
              <button className=' pr-5 text-red-700' onClick={() => setCancel(true)}> X</button>
              {cancel && (
                <Navigate to="/website" replace={true} />
              )}
            </div>
            {<Formik
              enableReinitialize={true}
              initialValues={{
                'product_name': initialData.product_name,
                'url': initialData.url,
                'product_tag': initialData.product_tag,
                "sitemap_url": initialData.sitemap_url,
                "header_space": initialData.header_space,
                "footer_space": initialData.footer_space,
                "gatag_ip": initialData.gatag_ip,
                "search_console_id": initialData.search_console_id,
                "conversin_tag": initialData.conversin_tag,
              }}
              validationSchema={yup.object().shape({
                sitemap_url: yup
                  .string()
                  .trim()
                    // eslint-disable-next-line no-useless-escape
                  .matches(/^[<]urlset\b[A-za-z0-9=*/\;:."'-_]*/, 'Is not in correct format')
              })}
              onSubmit={(values: any) => {
                // let parser = new DOMParser()
                // let xmlDoc = parser.parseFromString(values.sitemap_url, 'text/xml')


                if (queryType) {

                  updateWebsiteData({ "body": values, "id": queryId })
                  return
                }
                addWebsiteData(values)
              }}

            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue
                /* and other goodies */
              }) => (

                <form onSubmit={handleSubmit}>
                  <div className='h-auto  py-4 px-3 items-center justify-start pl-5 '>
                    <div className='grid grid-cols-12 gap-6'>

                      <div className='col-span-6'>
                        <label className="font-bold text-md" htmlFor="bootstrap-input">
                          Product Name
                        </label>
                        <TextField
                          size='medium'
                          type='text'
                          onChange={handleChange}
                          name='product_name'
                          value={values.product_name}
                          fullWidth
                          required

                        />
                      </div>


                      <div className='col-span-6'>
                        <label className="font-bold text-md" htmlFor="bootstrap-input">
                          Url
                        </label>
                        <TextField
                          size='medium'
                          type='text'
                          onChange={handleChange}
                          name='url'
                          value={values.url}
                          fullWidth
                          required

                        />
                      </div>
                      <div className='col-span-6' hidden>
                        <label className="font-bold text-md" htmlFor="bootstrap-input">
                          Order prefix
                        </label>
                        <TextField
                          size='medium'
                          type='text'
                          onChange={handleChange}
                          name='product_tag'
                          value={values.product_tag}
                          fullWidth
                          

                        />
                      </div>


                      <div className='col-span-6'>
                        <label className="font-bold text-md" htmlFor="bootstrap-input">
                          Gatag IP
                        </label>
                        <TextField
                          size='medium'
                          type='text'
                          onChange={handleChange}
                          name='gatag_ip'
                          value={values.gatag_ip}
                          fullWidth


                        />
                      </div>
                      <div className='col-span-6'>
                        <label className="font-bold text-md" htmlFor="bootstrap-input">
                          Search Console IP
                        </label>
                        <TextField
                          size='medium'
                          type='text'
                          onChange={handleChange}
                          name='search_console_id'
                          value={values.search_console_id}
                          fullWidth


                        />
                      </div>
                      <div className='col-span-6'>
                        <label className="font-bold text-md" htmlFor="bootstrap-input">
                          Header space
                        </label>
                        <TextareaAutosize
                          aria-label="minimum height"
                          minRows={3}
                          className="w-full border-slate-200 border-2 rounded-md"
                          // placeholder="Minimum 3 rows"
                          onChange={handleChange}
                          name='header_space'
                          value={values.header_space}
                        // required
                        />
                      </div>

                      <div className='col-span-12'>
                        <label className="font-bold text-md" htmlFor="bootstrap-input">
                          Footer Space
                        </label>
                        <TextareaAutosize
                          aria-label="minimum height"
                          minRows={3}
                          className="w-full  border-slate-200 border-2 rounded-md"
                          // placeholder="Minimum 3 rows"
                          onChange={handleChange}
                          name='footer_space'
                          value={values.footer_space}
                        />
                      </div>
                      <div className='col-span-12' hidden>
                        <label className="font-bold text-md" htmlFor="bootstrap-input">
                          Conversin Tag
                        </label>
                        <TextareaAutosize
                          // aria-label="minimum height"
                          // style={{width: '100'}}
                          className="w-full border-slate-200 border-2 rounded-md"
                          onChange={handleChange}
                          minRows={3}
                          // placeholder="Minimum 3 rows"
                          name='conversin_tag'
                          value={values.conversin_tag}
                        // required
                        />
                      </div>
                      <div className='col-span-12'>
                        <label className="font-bold text-md" htmlFor="bootstrap-input">
                          Sitemap
                        </label>
                        {/* <TextField
                          size='medium'
                          type='text'
                          onChange={handleChange}
                          name='sitemap_url'
                          value={values.sitemap_url}
                          fullWidth
                          

                        /> */}
                        <TextareaAutosize
                          aria-label="minimum height"
                          minRows={8}
                          className="w-full  border-slate-200 border-2 rounded-md"
                          // placeholder="Minimum 3 rows"
                          onChange={handleChange}
                          name='sitemap_url'
                          value={values.sitemap_url}

                        />
                        <ErrorMessage name="sitemap_url">
                          {msg => <div style={{ color: 'red' }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>

                  <div className='flex py-6 mx-4 items-end justify-end'>
                    <Button
                      type='submit'
                      variant='contained'
                    >
                      Submit
                    </Button>
                  </div>
                </form>)
              }
            </Formik>}

          </Paper>
          <hr />
        </div >
      </SideNavLayout >
    )
  }

export default AddWebsite
