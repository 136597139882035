import { Button, Paper, TextField } from "@mui/material";
import { format } from "date-fns";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { ImDatabase } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SideNavLayout from "../../components/layouts/sideNavLayout/SideNavLayout";
import {
  setSearchValue,
  setDataDashboardFilter as setDataFilterForOrder,
} from "../../redux/slices/OrderSlices";
import { RootState } from "../../redux/store";
import { useGetAllCenterQuery } from "../../services/AdminServices";
import { useGetOrderTodayQuery } from "../../services/OrderServices";

const Dashboard = () => {
  const { data: allCenter, isLoading, isFetching } = useGetAllCenterQuery("");
  const [callCenterValue, setCallCenterValue] = useState("");
  const [todayDate, setTodayDate] = useState("");

  const [callCenter, setCallCenter] = useState([]);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setCallCenter(allCenter?.data);
    }
  }, [allCenter, isLoading, isFetching]);
  const orderState: any = useSelector((state: RootState) => state.order);
  const { dataDashboardFilter: dataFilterForOrder } = orderState;
  console.log("dataFilterForOrder", dataFilterForOrder);
  const { data: todayorder, isLoading: loader } = useGetOrderTodayQuery(
    dataFilterForOrder,
    {
      skip: !dataFilterForOrder.start_date && !dataFilterForOrder.end_date,
    }
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    setTodayDate({
      ...dataFilterForOrder,
      start_date: format(new Date(), "yyyy-MM-dd"),
      end_date: format(new Date(), "yyyy-MM-dd"),
    });
  }, []);

  useEffect(() => {
    dispatch(setDataFilterForOrder(todayDate));
  }, [todayDate, dispatch]);

  //dashboard card
  const todayDataCard = todayorder?.data?.map((ele: any, index: number) => {
    return ele.idtag.includes(callCenterValue) ? (
      <Paper
        key={ele.idtag + index}
        onClick={() => {
          dispatch(setSearchValue(ele.idtag));
          dispatch(
            setDataFilterForOrder({
              ...dataFilterForOrder,
              status: "Confirmed",
              idtag: ele.idtag,
            })
          );
          navigate("/orders");
        }}
        sx={{
          backgroundImage: `linear-gradient( 83.2deg,  rgba(150,93,233,1) 10.8%, rgba(99,88,238,1) 94.3% )`,
          cursor: "pointer",
        }}
      >
        <div className="p-3 text-white">
          <div className="text-center p-1">
            <p>{ele.idtag}</p>
            <p className="font-bold">{ele.product_name}</p>
            <p>{ele.totalcount}</p>
          </div>
          <div className=" gap-2 mt-2 w-full">
            <div className="  flex flex-1 w-full">
              <div className="border p-2 flex  flex-col justify-between text-center flex-1">
                <p className="font-semibold">
                  Pending <br /> Order
                </p>
                <p className="font-semibold">{ele.PendingCount}</p>
              </div>
              <div className="border p-2 flex  flex-col justify-between text-center flex-1">
                <p className="font-semibold">
                  Confirmed <br /> Order
                </p>
                <p className="font-semibold">{ele.ConfirmedCount}</p>
              </div>
              <div className="border p-2 flex  flex-col justify-between text-center flex-1">
                <p className="font-semibold">
                  Shipped <br /> Order
                </p>
                <p className="font-semibold">{ele.ShippedCount}</p>
              </div>
              <div className="border p-2 flex  flex-col justify-between text-center flex-1">
                <p className="font-semibold">
                  Cancelled <br /> Order
                </p>
                <p className="font-semibold">{ele.CancelledCount}</p>
              </div>
            </div>
            <div className="flex flex-1 w-full">
              <div className="border p-2 flex  flex-col justify-between text-center flex-1">
                <p className="font-semibold">Online</p>
                <p className="font-semibold">{ele.OnlineCount}</p>
              </div>
              <div className="border p-2 flex  flex-col justify-between text-center flex-1">
                <p className="font-semibold">Enquiry</p>
                <p className="font-semibold">{ele.EnquiryCount}</p>
              </div>
              <div className="border p-2 flex  flex-col justify-between text-center flex-1">
                <p className="font-semibold">COD</p>
                <p className="font-semibold">{ele.CodCount}</p>
              </div>
              <div className="border p-2 flex  flex-col justify-between text-center flex-1">
                <p className="font-semibold">Total Order</p>
                <p className="font-semibold">{ele.totalcount}</p>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    ) : null;
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      start_date: format(new Date(), "yyyy-MM-dd"),
      end_date: format(new Date(), "yyyy-MM-dd"),
    },
    onSubmit: (values) => {
      dispatch(
        setDataFilterForOrder({
          ...dataFilterForOrder,
          start_date: values.start_date,
          end_date: values.end_date,
        })
      );
    },
  });
  return (
    <SideNavLayout>
      <div className="w-full px-2 py-2 mt-3  h-full overflow-auto">
        <Paper elevation={3}>
          <h1 className="mx-5 pt-3 font-bold">Today's Order </h1>
          <div className="mt-4 border-2 p-3 rounded mx-5">
            <h4 className="font-bold">Centers</h4>
            <div className=" mt-3 flex flex-cols gap-1">
              <p
                key={"all"}
                className={`border  rounded-3xl p-2  ${
                  !callCenterValue ? "bg-blue-300" : "bg-slate-300"
                } `}
                onClick={() => setCallCenterValue("")}
              >
                All
              </p>
              {callCenter?.map((center: any) => {
                return center?.callcenter ? (
                  <p
                    key={center?.callcenter}
                    className={`border ${
                      center?.callcenter === callCenterValue
                        ? "bg-blue-300"
                        : "bg-slate-300"
                    }  rounded-3xl p-2 `}
                    onClick={() => setCallCenterValue(center?.callcenter)}
                  >
                    {center?.callcenter}
                  </p>
                ) : null;
              })}
            </div>
          </div>
          <div className="">
            <form action="" onSubmit={formik.handleSubmit}>
              <div className="flex justify-end flex-col md:flex-row gap-5 px-5 py-5">
                <div className="">
                  <TextField
                    type="date"
                    name="start_date"
                    size="small"
                    onChange={formik.handleChange}
                    value={formik.values.start_date}
                    placeholder="Search for orders..."
                    className="border border-slate-300 rounded text-sm text-slate-500 py-1 px-2  outline-slate-300 w-full "
                  />
                </div>
                <div className="col-span-2">
                  <TextField
                    type="date"
                    size="small"
                    name="end_date"
                    onChange={formik.handleChange}
                    value={formik.values.end_date}
                    placeholder="Search for orders..."
                    className="border border-slate-300 rounded text-sm text-slate-500 py-1 px-2  outline-slate-300 w-full "
                  />
                </div>
                <div className="col-span-4">
                  <div>
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      size="medium"
                      sx={{ background: "#800000c7" }}
                      disabled={loader}
                      className={`m-1 bg-green-600 ${
                        loader ? "bg-slate-600" : "bg-green-600"
                      } rounded  `}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {!(todayDataCard === undefined) && !(todayDataCard?.length === 0) ? (
            <div className="grid  lg:grid-cols-3 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4 p-2">
              {todayDataCard}
            </div>
          ) : (
            <div className={`flex flex-col justify-center items-center py-5`}>
              <div className="text-4xl mb-2 text-primary-300">
                <ImDatabase />
              </div>
              <div className="text-slate-700">No Data Found</div>
              <div className="text-slate-400 text-sm">
                Sorry, we couldn't find any data.
              </div>
            </div>
          )}
        </Paper>
      </div>
    </SideNavLayout>
  );
};

export default Dashboard;
