import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit'
import { UserData } from "../../models/auth.model";

export interface LoginSliceStateType{
    token:string | null | undefined
    user:UserData | null

}
const userData = localStorage.getItem("user") ? localStorage.getItem("user") :  null

const initialState:LoginSliceStateType={
    token:localStorage.getItem("authToken") ? localStorage.getItem("authToken") : null,
    user:userData ? JSON.parse(userData) : null

}
 const authSlice:any= createSlice({
    name:"auth",
    initialState,
    reducers:{
        setAuth:(state,action: PayloadAction<LoginSliceStateType>)=>{
            state.token = action.payload.token
            state.user = action.payload.user
        }
    }

})
export const {
    setAuth
} = authSlice.actions
export default authSlice.reducer