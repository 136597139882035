import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from "../utils/constant";

export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}` }),
    endpoints: (builder) => ({
        //***** Login User *****/

        signIn: builder.mutation({
            query: ({ username, password }) => ({
                url: "/login/",
                method: "POST",
                body: { username, password }
            })
        })
    })
})

export const { useSignInMutation } = authApi