import React, { useEffect } from "react";
import SideNavLayout from "../../../components/layouts/sideNavLayout/SideNavLayout";
import MOLTable from "../../../components/UI/molecules/MOLTable/MOLTable";
import { headersTypes } from "../../../components/UI/molecules/MOLTable/MOLTablePropTypes";
import { ATMPagination } from "../../../components/UI/atoms";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  setDataDashboardFilter,
  setFilterBy,
} from "../../../redux/slices/OrderSlices";
import { Paper, Button, Autocomplete, TextField } from "@mui/material";
import { useFormik } from "formik";
import { format } from "date-fns";
export interface orderStatusFilterTypes {
  label: string;
  value: any[];
  key:
    | "Online"
    | "CONFIRMED"
    | "Enquiry"
    | "COD"
    | "LATEST_ORDER"
    | "ALL"
    | "Overseas";
}

export const orderModeFilter: orderStatusFilterTypes[] = [
  {
    label: "All",
    value: [],
    key: "ALL",
  },
  {
    label: "Latest Order",
    value: ["LATEST_ORDER"],
    key: "LATEST_ORDER",
  },
  {
    label: "All COD",
    value: ["COD"],
    key: "COD",
  },
  {
    label: "All Online",
    value: ["Online"],
    key: "Online",
  },
  {
    label: "All Enquiry",
    value: ["Enquiry"],
    key: "Enquiry",
  },
  {
    label: "All International",
    value: ["Overseas"],
    key: "Overseas",
  },
];

interface OrderListingPropTypes {
  headers: headersTypes[];
  rows: any[];
  rowCount?: number;
  sortValue: { field: string; value: "DESC" | "ASC" };
  onSortValueChange: (newValue: {
    field: string;
    value: "DESC" | "ASC";
  }) => void;
  page?: number;
  rowsPerPage?: number;
  onPageChange?: (newValue: number) => void;
  onRowsPerPageChange?: (newValue: number) => void;
  searchValue?: string;
  setSearchValue?: (newValue: string) => void;
  onRowClick?: (row: any) => void;
  isTableLoading?: boolean;
  orderStatusWiseCountData?: {
    LATEST_ORDER: number;
    Online: number;
    CONFIRMED: number;
    Enquiry: number;
    COD: number;
  };
  handleFileChange?: any;
  setDataFilter?: React.Dispatch<
    React.SetStateAction<{
      start_date: string;
      end_date: string;
      status: string;
      idtag: string;
    }>
  >;
  dataFilter?: {
    start_date: string;
    end_date: string;
    status: string;
    idtag: string;
  };
  loader: boolean;
}

const OrderListing = ({
  headers,
  rows,
  rowCount = 0,
  sortValue,
  onSortValueChange,
  page = 1,
  rowsPerPage = 5,
  onPageChange = () => {},
  onRowsPerPageChange = () => {},
  searchValue = "",
  setSearchValue = () => {},
  onRowClick,
  isTableLoading,
  orderStatusWiseCountData = {
    LATEST_ORDER: 0,
    Online: 0,
    CONFIRMED: 0,
    Enquiry: 0,
    COD: 0,
  },

  setDataFilter = () => {},
  dataFilter,
  loader,
}: OrderListingPropTypes) => {
  // Hooks
  const orderState: any = useSelector((state: RootState) => state.order);
  const dispatch = useDispatch<AppDispatch>();

  // Slice States
  const { filterBy, dataDashboardFilter } = orderState;
  let orderStatusOptions = [
    { label: "Confirmed", value: "Confirmed" },
    { label: "Pending", value: "Pending" },
    { label: "Cancelled", value: "Cancelled" },
    { label: "Callback", value: "Callback" },
    { label: "Not Interested", value: "Not Interested" },
    { label: "No Response", value: "No Response" },
  ];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: {
        label: dataFilter?.status ? dataFilter?.status : "",
        value: dataFilter?.status ? dataFilter?.status : "",
      },
      start_date: dataFilter?.start_date || "",
      end_date: dataFilter?.end_date || "",
    },
    onSubmit: (values) => {
      setDataFilter({
        idtag: dataFilter?.idtag ? dataFilter?.idtag : "",
        status: values?.status.value,
        start_date: values?.start_date,
        end_date: values?.end_date,
      });
    },
  });
  console.log("dataDashboardFilter", dataDashboardFilter);
  useEffect(() => {
    if (
      filterBy[0].fieldName === "mode" &&
      filterBy[0].value[0] === "LATEST_ORDER"
    ) {
      let date = format(new Date(), "yyyy/MM/dd");
      setDataFilter({
        idtag: "",
        status: "",
        start_date: date,
        end_date: date,
      });
    } else {
      setDataFilter({
        idtag: dataDashboardFilter.idtag,
        status: dataDashboardFilter?.status || "",
        start_date: dataDashboardFilter?.start_date || "",
        end_date: dataDashboardFilter?.end_date || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBy]);
  console.log("dataDashboardFilter", dataDashboardFilter);
  const handleReset = () => {
    dispatch(
      setDataDashboardFilter({
        idtag: "",
        status: "",
        start_date: "",
        end_date: "",
      })
    );
    setDataFilter({
      idtag: "",
      status: "",
      start_date: "",
      end_date: "",
    });
  };
  return (
    <SideNavLayout>
      <div className="w-full p-3  h-full overflow-auto">
        <div className="text-slate-700 text-lg  "> Orders </div>
        <div className="text-xs text-slate-500 ">
          {" "}
          Manage your all orders here{" "}
        </div>
        <Paper className="px-2 py-2 mt-3" elevation={3}>
          {/* Order Status Filter */}
          <div className=" flex border-b border-slate-200 py-1  gap-4">
            {orderModeFilter.map(
              (item: orderStatusFilterTypes, index: number) => (
                <div
                  key={index}
                  onClick={() => {
                    dispatch(
                      setFilterBy(
                        filterBy.map((ele: any) => {
                          if (ele.fieldName === "mode") {
                            return {
                              ...ele,
                              value: item.value,
                            };
                          } else {
                            return ele;
                          }
                        })
                      )
                    );
                  }}
                  className={`flex gap-1 px-1 py-1 text-slate-400 text-sm hover:text-black cursor-pointer ${
                    filterBy.find((ele: any) => ele.fieldName === "mode")
                      .value[0] === item.value[0] &&
                    "text-black border-b-4 border-primary-400"
                  } `}
                >
                  {item.label}
                </div>
              )
            )}
          </div>

          <div className="grid grid-cols-12 my-3 rounded gap-2 ">
            {/* Search Box */}
            <div className="col-span-2">
              <input
                type="text"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                placeholder="Search for orders..."
                className="border border-slate-300 rounded text-sm text-slate-500 py-1 px-2  outline-slate-300 w-full "
              />
            </div>

            {
              <div className="col-start-3 gap-2 mr-5 col-end-13 flex justify-end">
                <form
                  action=""
                  onSubmit={formik.handleSubmit}
                  onReset={formik.handleReset}
                >
                  <div className="col-start-2 gap-2 mr-5 col-end-12 flex justify-end">
                    <div className="col-span-3">
                      <Autocomplete
                        options={orderStatusOptions}
                        value={formik.values.status}
                        size="small"
                        id="combo-box-demo"
                        onChange={(event, newValue) => {
                          formik.setFieldValue("status", newValue);
                        }}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField name="status" {...params} label="status" />
                        )}
                      />
                    </div>
                    <div className="col-span-3">
                      <TextField
                        type="date"
                        name="start_date"
                        size="small"
                        onChange={formik.handleChange}
                        value={formik.values.start_date}
                        placeholder="Search for orders..."
                        className="border border-slate-300 rounded text-sm text-slate-500 py-1 px-2  outline-slate-300 w-full "
                      />
                    </div>
                    <div className="col-span-2">
                      <TextField
                        type="date"
                        size="small"
                        name="end_date"
                        onChange={formik.handleChange}
                        value={formik.values.end_date}
                        placeholder="Search for orders..."
                        className="border border-slate-300 rounded text-sm text-slate-500 py-1 px-2  outline-slate-300 w-full "
                      />
                    </div>
                    <div className="col-span-4">
                      <div className="gap-2 mr-5 flex justify-end">
                        <Button
                          type="submit"
                          variant="contained"
                          size="medium"
                          sx={{ background: "#800000c7" }}
                          disabled={loader}
                          className={`m-1 bg-green-600 ${
                            loader ? "bg-slate-600" : "bg-green-600"
                          } rounded  `}
                        >
                          Submit
                        </Button>

                        <Button
                          type="reset"
                          onClick={() => {
                            handleReset();
                          }}
                          variant="contained"
                          size="medium"
                          sx={{ background: "#800000c7" }}
                          className=" m-1 bg-green-600 rounded  "
                        >
                          reset
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            }
          </div>

          {/* Table */}
          <div
            className="border rounded border-slate-100 max-h-[70vh] overflow-scroll scroll-smooth"
            id="scroll-top"
          >
            <MOLTable
              headers={headers}
              rows={rows}
              sortValue={sortValue}
              onSortValueChange={onSortValueChange}
              onRowClick={onRowClick}
              rowsPerPage={rowsPerPage}
              isLoading={isTableLoading}
            />
          </div>

          {/* Pagination */}
          <div className="mt-2 py-2">
            <ATMPagination
              page={page}
              onPageChange={(newValue) => onPageChange(newValue)}
              rowCount={rowCount}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(newPage) => onRowsPerPageChange(newPage)}
              rows={rows}
            />
          </div>
        </Paper>
      </div>
    </SideNavLayout>
  );
};

export default OrderListing;
