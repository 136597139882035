import { TextField } from "@mui/material"
import { InputAdornment } from "@mui/material"
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { ATMPasswordPropType } from "./ATMPasswordPropType";
import IconButton from '@mui/material/IconButton';
import { useState } from "react";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ErrorMessage } from 'formik'


const ATMPassword = ({
    size = "small",
    placeholder = "",
    variant = "standard",
    extraClasses = "",
    onChange = () => { },
    name,
    value,
    required = false,
    label,
    disabled = false,
}: ATMPasswordPropType) => {
    const [isShowPassowrd, setIsShowPassword] = useState(false)
    return (
        <div className="relative">
            <TextField
                size={size}
                placeholder={placeholder}
                variant={variant}
                sx={{ width: "100%" }}
                className={extraClasses}
                onChange={(e) => onChange(e)}
                name={name}
                label={label}
                value={value}
                type={isShowPassowrd ? "text" : "password"}
                disabled={disabled ? true : false}
                InputLabelProps={{
                    style: { fontSize: "14px", fontFamily: "poppins" }
                }}
                InputProps={{
                    style: { fontSize: "14px", fontFamily: "poppins" },
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setIsShowPassword(!isShowPassowrd)}
                                edge="end"

                            >
                                {isShowPassowrd ?
                                    <VisibilityOutlinedIcon />
                                    :
                                    <VisibilityOffIcon />
                                }

                            </IconButton>

                        </InputAdornment>
                    ),
                }} />
            <ErrorMessage name={name} >
                {
                    (errMsg) => (
                        <p className="font-poppins absolute text-normal-txt text-start mt-0 text-primary-500"> {errMsg} </p>
                    )
                }
            </ErrorMessage>
        </div>
    )
}

export default ATMPassword
