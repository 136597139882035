import React, { useEffect, useState } from "react";
import { MdModeEditOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { headersTypes } from "../../../components/UI/molecules/MOLTable/MOLTablePropTypes";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setSortValue,
  setTotalItems,
} from "../../../redux/slices/BlogSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import { useGetAllBlogDataWithPaginationQuery } from "../../../services/webSiteBlog";
// import { useGetOrdersWithPaginationQuery } from '../../../services/OrderServices'
// import { useGetAllWebsiteDataWithPaginationQuery } from '../../../services/WebsiteService'
import BlogViewList from "./BlogViewList";

// import moment from 'moment'
// useGetOrderCountQuery,

const BlogViewListingWrapper = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { search } = useLocation();

  let queryValue = new URLSearchParams(search).get("search");
  let websiteName = new URLSearchParams(search).get('name')

  const headers: headersTypes[] = [
    {
      name: "Blog Description",
      field: "blog_description",
      isSortable: false,
      flex: 2,
    },
    {
      name: "image URL",
      field: "blog_image",
      isSortable: false,
      flex: 2,
    },

    {
      name: "blog subtitle",
      field: "blog_subtitle",
      isSortable: false,
      flex: 2,
    },
    {
      name: "Blog title",
      field: "blog_title",
      isSortable: false,
      flex: 2,
    },
    {
      name: "Action",
      field: "Action",
      isSortable: false,
      flex: 2,
      renderCell: (row: any) => {
        return (
          <div className="flex p-2 px-4 text-center">
            {/* <HiOutlineDocumentAdd className="text-3xl mr-5" title='View Website info' onClick={() => navigate(`/website-page-view?website_id=${row.website_id}&website_page_id=${row.website_page_id}`)} /> */}
            <MdModeEditOutline
              className="text-3xl"
              title="website Edit"
              onClick={() =>
                navigate(
                  `/add-blog?website_id=${row.website_id}&page_id=${row.website_blogs_id}&type="edit"&name=${websiteName}`
                )
              }
            />
          </div>
        );
      },
    },
  ];
  const [dataFilter, setDataFilter] = useState({
    start_date: "",
    end_date: "",
    status: "",
  });

  const websitePageState: any = useSelector((state: RootState) => state.blog);
  const {
    page,
    rowsPerPage,
    sortValue,
    searchValue,
    items,
    totalItems,
    isTableLoading,
    filterBy,
  } = websitePageState;

  const { data, isFetching, isLoading } = useGetAllBlogDataWithPaginationQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    page: page,
    website_id: queryValue as string,
    filterBy: filterBy,
    dateFilter: dataFilter,
  });

  useEffect(() => {
    if (!isFetching && !isLoading) {
      dispatch(setItems(data?.data || 0));
      dispatch(setTotalItems(data?.totalItem || 0));
      dispatch(setIsTableLoading(false));
    } else {
      dispatch(setIsTableLoading(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, isLoading, data]);

  return (
    <>
      <BlogViewList
        headers={headers}
        rows={items}
        rowCount={totalItems}
        sortValue={sortValue}
        onSortValueChange={(newValue) => dispatch(setSortValue(newValue))}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(newValue) => dispatch(setPage(newValue))}
        onRowsPerPageChange={(newValue) => dispatch(setRowsPerPage(newValue))}
        searchValue={searchValue}
        setSearchValue={(newValue) => dispatch(setSearchValue(newValue))}
        isTableLoading={isTableLoading}
        setDataFilter={setDataFilter}
        dataFilter={dataFilter}
      />
    </>
  );
};

export default BlogViewListingWrapper;
