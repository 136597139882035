import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiDataWithPaginationTypes } from '../models/commonModel';

import { BASE_URL } from "../utils/constant";
const authToken = localStorage.getItem('authToken') || ""

export const websiteBlogApi = createApi({
    reducerPath: "websiteBlogApi",
    tagTypes: ['blog'],
    baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/website/blog` }),
    endpoints: (builder) => ({
        getAllBlogDataWithPagination: builder.query({
            providesTags:['blog'],
            query: (body: ApiDataWithPaginationTypes) => ({
                url: "",
                headers: {
                    'x-access-token': authToken
                },
                method: "POST",
                body,
            })
        }),

        addBlogData: builder.mutation({
            invalidatesTags:['blog'],
            query: (body) => {
                
                return ({
                    url: `/add`,
                    headers: {
                        'x-access-token': authToken
                    },
                    method: "POST",
                    body,
                })
            }


        }),

        getBlogData:builder.query({
            providesTags:['blog'],
            query: ({id}) => {
                return ({
                    url: `/view/${id}`,
                    headers: {
                        'x-access-token': authToken
                    },
                    method: "GET",
                   
                })
            }

        }),

        updateBlogData: builder.mutation({
            invalidatesTags:['blog'],
            query: ({body,id}) => {
                
                return ({
                    url: `/update/${id}`,
                    headers: {
                        'x-access-token': authToken
                    },
                    method: "PUT",
                    body,
                })
            }


        }),



    })

})

export const { useAddBlogDataMutation,useGetAllBlogDataWithPaginationQuery,useGetBlogDataQuery,useUpdateBlogDataMutation} = websiteBlogApi