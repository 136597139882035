
import {string} from 'yup'
import { regIndiaPhone } from './regex';

export const isValidEmail = ()=>{
    return string().email("Please provide a valid email address");
}
export const isValidPassword = ()=>{
    return string();
}
export const isValidMobile = ()=>{
    return string().matches(regIndiaPhone,"Please enter valid number");
}

