import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: { isLoading: boolean } = {
    isLoading: false,

}

const loaderSlice: any = createSlice({
    name: "loader",
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        }
    }

})
export const {
    setIsLoading
} = loaderSlice.actions
export default loaderSlice.reducer