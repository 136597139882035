import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiDataWithPaginationTypes } from '../models/commonModel';

import { BASE_URL } from "../utils/constant";
const authToken = localStorage.getItem('authToken') || ""

export const websiteApi = createApi({
    reducerPath: "websiteApi",
    baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/website` }),
    endpoints: (builder) => ({
        getAllWebsiteDataWithPagination: builder.query({
            query: (body: ApiDataWithPaginationTypes) => ({
                url: "",
                headers: {
                    'x-access-token': authToken
                },
                method: "POST",
                body,
            })
        }),

        addWebsiteData: builder.mutation({
            query: (body) => {
                
                return ({
                    url: `/add`,
                    headers: {
                        'x-access-token': authToken
                    },
                    method: "POST",
                    body,
                })
            }


        }),

        getWebsiteData:builder.query({
            query: ({id}) => {
                
                return ({
                    url: `/view/${id}`,
                    headers: {
                        'x-access-token': authToken
                    },
                    method: "GET",
                   
                })
            }

        }),

        updateWebsiteData: builder.mutation({
            query: ({body,id}) => {
                
                return ({
                    url: `/update/${id}`,
                    headers: {
                        'x-access-token': authToken
                    },
                    method: "PUT",
                    body,
                })
            }


        }),



    })

})

export const { useGetAllWebsiteDataWithPaginationQuery, useAddWebsiteDataMutation,useGetWebsiteDataQuery,useUpdateWebsiteDataMutation } = websiteApi