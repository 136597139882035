
import {  Button, Paper, TextareaAutosize, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SideNavLayout from '../../../components/layouts/sideNavLayout/SideNavLayout'
import { Formik } from 'formik'
import { Navigate, useLocation } from 'react-router-dom'
import showToast from '../../../utils/toast'
import { useAddWebsitePageDataMutation, useGetWebsitePageDataQuery, useUpdateWebsitePageDataMutation } from '../../../services/websitePageService'
// import showToast from '../../../utils/toast'

const AddPage
  = () => {
    const { search } = useLocation()
    const [cancel, setCancel] = useState(false)
    let website_id = new URLSearchParams(search).get('website_id')
    let website_page_id = new URLSearchParams(search).get('page_id')
    let type = new URLSearchParams(search).get('type')

    const [initialData, setInitialData] = useState({
      'website_id': website_id,
      'page_url': "",
      'page_name': "",
      "header_space": "",
      "footer_space": "",
    })
    const { data, isFetching, isLoading } = useGetWebsitePageDataQuery({ "id": website_page_id }, { skip: !website_page_id })
    const [updateWebsitePageData, updateWebsitePageDataInfo] = useUpdateWebsitePageDataMutation()
    const [addWebsiteData, addWebsiteDataInfo] = useAddWebsitePageDataMutation()

    useEffect(() => {
      if (!addWebsiteDataInfo.isLoading && addWebsiteDataInfo.isSuccess) {
        if (addWebsiteDataInfo?.data?.status) {
          showToast(addWebsiteDataInfo.data.msg, "success")
          // window.location.pathname = '/website'
        }
      }
    }, [addWebsiteDataInfo])



    useEffect(() => {
      if (!updateWebsitePageDataInfo.isLoading && updateWebsitePageDataInfo.isSuccess) {
        if (updateWebsitePageDataInfo?.data?.status) {
          showToast(updateWebsitePageDataInfo.data.msg, "success")


        }
      }


    }, [updateWebsitePageDataInfo])
    useEffect(() => {
      if (!isLoading && !isFetching && data) {
        let singleData = {
          "website_id": data?.data.website_id,
          "page_name": data?.data.page_name,
          "page_url": data?.data.page_url,
          "header_space": data?.data.header_space,
          "footer_space": data?.data.footer_space,
        }
        setInitialData(singleData)
      }
    }, [isLoading, isFetching, data])
    return (
      <SideNavLayout>
        <div className='w-full px-2 py-2 mt-3  h-full overflow-auto'>
          <Paper elevation={3}  >
            <div className='flex py-2 items-center justify-between'>
              <h1 className='mx-5 font-bold'> {type ? "Edit" : "Add"} Page</h1>
              {/* <button className=' pr-5 text-red-700' onClick={() => window.location.pathname = '/website-view'}> X</button> */}
              <button className=' pr-5 text-red-700' onClick={() => setCancel(true)}> X</button>
              {cancel && (
                <Navigate to={`/website-view?search=${website_id}`} replace={true} />
              )}
            </div>

            {<Formik
              enableReinitialize={true}
              initialValues={{
                'website_id': initialData.website_id,
                'page_url': initialData.page_url,
                'page_name': initialData.page_name,
                "header_space": initialData.header_space,
                "footer_space": initialData.footer_space,
              }}

            onSubmit={(values: any) => {
              if (type) {

                updateWebsitePageData({ "body": values, "id": website_page_id })
                return
              }
              addWebsiteData(values)
            }}

            >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue
              /* and other goodies */
            }) => (

              <form onSubmit={handleSubmit}>
                <div className='h-auto  py-4 px-3 items-center justify-start pl-5 '>
                  <div className='grid grid-cols-12 gap-6'>
                    <div className='col-span-6'>
                      <label className="font-bold text-md" htmlFor="bootstrap-input">
                        page url
                      </label>
                      <TextField
                        size='medium'
                        type='text'
                        onChange={handleChange}
                        name='page_url'
                        value={values.page_url}
                        fullWidth
                        required

                      />
                    </div>

                    <div className='col-span-6'>
                      <label className="font-bold text-md" htmlFor="bootstrap-input">
                        Page Name
                      </label>
                      <TextField
                        size='medium'
                        type='text'
                        onChange={handleChange}
                        name='page_name'
                        value={values.page_name}
                        fullWidth
                        

                      />
                    </div>

                    <div className='col-span-6'>
                      <label className="font-bold text-md" htmlFor="bootstrap-input">
                        Header space
                      </label>
                      <TextareaAutosize
                        aria-label="minimum height"
                        minRows={3}
                        className="w-full border-slate-200 border-2 rounded-md"
                        // placeholder="Minimum 3 rows"
                        onChange={handleChange}
                        name='header_space'
                        value={values.header_space}
                      // required
                      />
                    </div>

                    <div className='col-span-6'>
                      <label className="font-bold text-md" htmlFor="bootstrap-input">
                        Footer Space
                      </label>
                      <TextareaAutosize
                        aria-label="minimum height"
                        minRows={3}
                        className="w-full  border-slate-200 border-2 rounded-md"
                        // placeholder="Minimum 3 rows"
                        onChange={handleChange}
                        name='footer_space'
                        value={values.footer_space}
                      // required
                      />
                    </div>



                    {/* <div className='col-span-6'>
                      <label className="font-bold text-md" htmlFor="bootstrap-input">
                        page Url
                      </label>
                      <TextField
                        size='medium'
                        type='text'
                        onChange={handleChange}
                        name='url'
                        value={values.url}
                        fullWidth
                        required

                      />
                    </div> */}
                    {/* <div className='col-span-6'>
                      <label className="font-bold text-md" htmlFor="bootstrap-input">
                        Product tag
                      </label>
                      <TextField
                        size='medium'
                        type='text'
                        onChange={handleChange}
                        name='idtag'
                        value={values.idtag}
                        fullWidth
                        required

                      />
                    </div> */}
                  </div>
                </div>

                <div className='flex py-6 mx-4 items-end justify-end'>
                  <Button
                    type='submit'
                    variant='contained'
                  >
                    Submit
                  </Button>
                </div>
              </form>)
            }
          </Formik>}
        </Paper>
        <hr />
      </div >
      </SideNavLayout >
    )
  }

export default AddPage
