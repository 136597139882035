import { format } from "date-fns";

import React, { useEffect } from "react";
import { MdModeEditOutline, MdOutlineCreditScore } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { headersTypes } from "../../../components/UI/molecules/MOLTable/MOLTablePropTypes";
import {
  setIsTableLoading,
  setItems,
  setPage,
  setRowsPerPage,
  setSearchValue,
  setSortValue,
  setTotalItems,
  setDataFilter,
} from "../../../redux/slices/OrderSlices";
import { AppDispatch, RootState } from "../../../redux/store";
import { useGetOrdersWithPaginationQuery } from "../../../services/OrderServices";
import OrderListing from "./OrderListing";
// import moment from 'moment'
// useGetOrderCountQuery,

const OrderListingWrapper = () => {
  const navigate = useNavigate();
  const headers: headersTypes[] = [
    {
      name: "order id",
      field: "order_id",
      isSortable: false,
      flex: 2,
      renderCell: (row: any) => {
        return row.idtag + row.order_id;
      },
    },
    {
      name: "Ordered On",
      field: "date",
      isSortable: false,
      flex: 2,
      renderCell: (row: any) => {
        let date = format(new Date(row.date), "yyyy-MM-dd hh:mm a");
        return date;
      },
    },
    {
      name: "Mode",
      field: "mode",
      isSortable: false,
      flex: 2,
    },
    {
      name: "Product Name",
      field: "product_name",
      isSortable: false,
      flex: 2,
    },
    {
      name: "Name",
      field: "name",
      isSortable: false,
      flex: 2,
    },
    {
      name: "Email",
      field: "email",
      isSortable: false,
      flex: 2,
    },
    {
      name: "Phone",
      field: "phone",
      isSortable: false,
      flex: 2,
    },
    {
      name: "Address",
      field: "address",
      isSortable: false,
      flex: 2,
    },
    {
      name: "Status",
      field: "status",
      isSortable: false,
      flex: 2,
    },
    {
      name: "Payment confirm",
      field: "payment_confirm",
      isSortable: false,
      flex: 2,
    },
    {
      name: "Action",
      field: "Action",
      isSortable: false,
      flex: 2,
      renderCell: (row: any) => {
        return (
          <div className="flex p-2 px-4 text-center">
            <MdOutlineCreditScore
              className="text-3xl mr-5"
              title="update order"
              onClick={() =>
                navigate(
                  `/update-order?search=${row.idtag + row.order_id}&id=${
                    row.order_id
                  }`
                )
              }
            />
            <MdModeEditOutline
              className="text-3xl"
              title="Agent Edit"
              onClick={() => navigate(`/agent?search=${row.order_id}`)}
            />
          </div>
        );
      },
    },
  ];

  const orderState: any = useSelector((state: RootState) => state.order);
  const {
    page,
    rowsPerPage,
    sortValue,
    searchValue,
    items,
    totalItems,
    isTableLoading,
    filterBy,
    dataFilter,
  } = orderState;
  const dispatch = useDispatch<AppDispatch>();

  const { data, isFetching, isLoading } = useGetOrdersWithPaginationQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    page: page,
    filterBy: filterBy,
    dateFilter: dataFilter,
  });
  useEffect(() => {
    if (!isFetching && !isLoading) {
      dispatch(setItems(data?.data || []));
      dispatch(setTotalItems(data?.totalItem || 0));
      dispatch(setIsTableLoading(false));
    } else {
      dispatch(setIsTableLoading(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, isLoading, data]);

  return (
    <>
      <OrderListing
        headers={headers}
        rows={items}
        rowCount={totalItems}
        sortValue={sortValue}
        onSortValueChange={(newValue) => dispatch(setSortValue(newValue))}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(newValue) => dispatch(setPage(newValue))}
        onRowsPerPageChange={(newValue) => dispatch(setRowsPerPage(newValue))}
        searchValue={searchValue}
        setSearchValue={(newValue) => dispatch(setSearchValue(newValue))}
        isTableLoading={isTableLoading}
        orderStatusWiseCountData={totalItems}
        setDataFilter={(value) => dispatch(setDataFilter(value))}
        dataFilter={dataFilter}
        loader={isLoading}
      />
    </>
  );
};

export default OrderListingWrapper;
