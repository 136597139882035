
import { ATMButton, ATMInput, ATMPassword } from '../../components/UI/atoms';
import { Box, Grid } from '@mui/material';



const LoginForm = ({props,loading}:any) => {
    return (
        <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} md={10}>
            <Box sx={{ mt: 5 }}>
                <ATMInput
                    autofocus={true}
                    size="medium"
                    placeholder="Enter username here "
                    variant="outlined"
                    onChange={(e) => props.setFieldValue("username", e.target.value)}
                    name="username"
                    inputType="text"
                    label="Username"
                    required={true}
                    value={props.values.email}
                />
            </Box>

        </Grid>
        <Grid item xs={12} md={10}>
            <Box sx={{ mt: 5 }} >
                <ATMPassword
                    size="medium"
                    placeholder="Enter password here"
                    variant="outlined"
                    onChange={(e) => props.setFieldValue("password", e.target.value)}
                    name="password"
                    label="Password"
                    required={true}
                    value={props.values.password}
                />
            </Box>
        </Grid>
        <Grid item xs={12} md={10}>
            <Box sx={{ mt: 5 }}>
                <ATMButton
                    text="Sign In"
                    type="submit"
                    loading={loading}
                    extraClasses="w-full"
                />
            </Box>
        </Grid>
    </Grid>
    )
}

export default LoginForm

