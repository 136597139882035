import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BASE_URL } from "../utils/constant";
const authToken = localStorage.getItem("authToken") || "";

export const websitePageApi = createApi({
  reducerPath: "websitePageApi",
  tagTypes:['website-page'],
  baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/website/page` }),
  endpoints: (builder) => ({
    getAllWebsitePageDataWithPagination: builder.query({
      providesTags: ["website-page"],
      query: (body) => ({
        url: "",
        headers: {
          "x-access-token": authToken,
        },
        method: "POST",
        body,
      }),
    }),

    addWebsitePageData: builder.mutation({
      invalidatesTags: ["website-page"],
      query: (body) => {
        return {
          url: `/add`,
          headers: {
            "x-access-token": authToken,
          },
          method: "POST",
          body,
        };
      },
    }),

    getWebsitePageData: builder.query({
      providesTags: ["website-page"],
      query: ({ id }) => {
        return {
          url: `/view/${id}`,
          headers: {
            "x-access-token": authToken,
          },
          method: "GET",
        };
      },
    }),

    updateWebsitePageData: builder.mutation({
      invalidatesTags: ["website-page"],
      query: ({ body, id }) => {
        return {
          url: `/update/${id}`,
          headers: {
            "x-access-token": authToken,
          },
          method: "PUT",
          body,
        };
      },
    }),
    addWebsitePageMetaData: builder.mutation({
      invalidatesTags: ["website-page"],
      query: (body) => {
        return {
          url: `meta/add`,
          headers: {
            "x-access-token": authToken,
          },
          method: "post",
          body,
        };
      },
    }),
  }),
});

export const {
  useGetAllWebsitePageDataWithPaginationQuery,
  useAddWebsitePageDataMutation,
  useGetWebsitePageDataQuery,
  useAddWebsitePageMetaDataMutation,
  useUpdateWebsitePageDataMutation,
} = websitePageApi;
