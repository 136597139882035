import React from 'react'
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';

interface OnboardingTemplatePropType {
    children: JSX.Element
}

const OnboardingTemplate: React.FC<OnboardingTemplatePropType> = ({ children }) => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            className="main-body"
           >
            <Grid item xs={6} className="w-100 hidden" >
                <Box sx={{ height: "100%", backgroundColor: "grey" }}>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ p: 5 }} className="flex-1 flex justify-center items-center w-100">
                {children}
            </Grid>
        </Grid>
    )
}

export default OnboardingTemplate