import React, { useEffect, useState } from 'react'
import {  MdOutlineCreditScore } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { headersTypes } from '../../../components/UI/molecules/MOLTable/MOLTablePropTypes'
import { setIsTableLoading, setItems, setPage, setRowsPerPage, setSearchValue, setSortValue, setTotalItems } from '../../../redux/slices/websiteSlice'
import { AppDispatch, RootState } from '../../../redux/store'
import { useGetAllWebsiteDataWithPaginationQuery } from '../../../services/WebsiteService'
import BlogWebsiteList from './BLogWebsiteList'


const BlogWebsiteListingWrapper = () => {
    const navigate = useNavigate()
    const headers: headersTypes[] = [

        // {
        //     name: "Website Id",
        //     field: "website_id",
        //     isSortable: false,
        //     flex: 2,
        //     renderCell: (row: any) => {

        //     }
        // },
        {
            name: "URL",
            field: "url",
            isSortable: false,
            flex: 2,
            renderCell: (row: any) => {
                // eslint-disable-next-line  react/jsx-no-target-blank
                return <a href={row.url} target="_blank" className='text-blue-700'>{row.url}</a>
            }
        },

        {
            name: "Name",
            field: "product_name",
            isSortable: false,
            flex: 2
        },

        {
            name: "Product Tag",
            field: "product_tag",
            isSortable: false,
            flex: 2,

        },
        {
            name: "Action",
            field: "Action",
            isSortable: false,
            flex: 2,
            renderCell: (row: any) => {

                return (<div className='flex p-2 px-4 text-center'>
                    <MdOutlineCreditScore className="text-3xl mr-5" title='View blog info' onClick={() => navigate(`/blog-view?search=${row.website_id}&name=${row.product_name}`)} />
                    {/* <MdModeEditOutline className="text-3xl" title='website Edit' onClick={() => navigate(`/edit-website?website_id=${row.website_id}&type="edit"`)} /> */}
                    {/* <MdModeEditOutline className="text-3xl" title='blog Edit' onClick={() => navigate(`/add-blog?search=${row.website_id}&type=edit`)} /> */}
                </div>)
            }

        },

    ]
    const [dataFilter, setDataFilter] = useState({
        start_date: "",
        end_date: "",
        status: "",
    })


    const websiteState: any = useSelector((state: RootState) => state.website)
    const {
        page,
        rowsPerPage,
        sortValue,
        searchValue,
        items,
        totalItems,
        isTableLoading,
    } = websiteState

    const dispatch = useDispatch<AppDispatch>()

    const { data, isFetching, isLoading } = useGetAllWebsiteDataWithPaginationQuery({
        limit: rowsPerPage,
        searchValue: searchValue,
        page: page,
        searchKey: [
            "url",
            "product_name",
            "product_tag"
        ]
        // filterBy: filterBy,
        // dateFilter: dataFilter,
    })


    useEffect(() => {
        if (!isFetching && !isLoading) {
            dispatch(setItems(data?.data || []))
            dispatch(setTotalItems(data?.totalItem || 0))
            dispatch(setIsTableLoading(false))
        } else {
            dispatch(setIsTableLoading(true))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, isLoading, data])


    return (
        <>
            <BlogWebsiteList
                headers={headers}
                rows={items}
                rowCount={totalItems}
                sortValue={sortValue}
                onSortValueChange={(newValue) => dispatch(setSortValue(newValue))}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={(newValue) => dispatch(setPage(newValue))}
                onRowsPerPageChange={(newValue) => dispatch(setRowsPerPage(newValue))}
                searchValue={searchValue}
                setSearchValue={(newValue) => dispatch(setSearchValue(newValue))}
                isTableLoading={isTableLoading}
                setDataFilter={setDataFilter}
                dataFilter={dataFilter}
            />
        </>
    )
}

export default BlogWebsiteListingWrapper
