import React from 'react'
import { useSelector } from 'react-redux'
import FadeLoader from 'react-spinners/FadeLoader'
import { RootState } from '../../../../redux/store'

const ATMLoader = () => {

    const loaderState: any = useSelector((state: RootState) => state.loader)
    const {
        isLoading,
    } = loaderState
    return (
        <>
            {
                isLoading &&
                <div className='relative'>

                    <div className='absolute z-50  h-screen w-screen flex justify-center items-center ' style={{ backgroundColor: "hsl(0deg 0% 57% / 35%)" }}  >
                        <FadeLoader color='#ef4444' radius={20} />
                    </div>
                </div>
            }
        </>
    )
}

export default ATMLoader
