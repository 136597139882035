import React from 'react'
import SideNavLayout from '../../components/layouts/sideNavLayout/SideNavLayout'
const FileManage = () => {
  return (
    <SideNavLayout>
      <div>FileManage</div>

    </SideNavLayout>
  )
}

export default FileManage