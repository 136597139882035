
import { Autocomplete, Button, Paper, TextField } from '@mui/material'
import React, { useState, useEffect } from 'react'
import SideNavLayout from '../../components/layouts/sideNavLayout/SideNavLayout'
import { useGetOrderByIdQuery, useUpdateOrderByIdMutation } from '../../services/OrderServices'
import { Formik } from 'formik'
import showToast from '../../utils/toast'
import { useLocation } from 'react-router-dom'

const AgentView = () => {
  const { search } = useLocation()
  let queryValue = new URLSearchParams(search).get('search')
  const [setUpdateOrder, updateOrderInfo] = useUpdateOrderByIdMutation()
  const [order, setOrder] = useState(queryValue ? queryValue : "")
  const [orderId, setOrderid] = useState("")
  let initialData = {
    "product_name": { value: "", label: "" },
    'name': "",
    'phone': "",
    'email': "",
    'status': "",
    'zip_code': "",
    'state': "",
    'remark': "",
    'quantity': "",
    'address': "",
    'mode': "",
    'city': "",

  }
  const [orderData, setOrderData] = useState(initialData)
  let productOptions = [{ label: 'Dhuandhar Plus', value: 'Dhuandhar Plus' },
  { label: 'Alcoban Plus', value: 'Alcoban Plus' },
  { label: 'Sandhi Sudha Plus', value: 'Sandhi Sudha Plus' },
  { label: 'ISO Herbal', value: 'ISO Herbal' },
  { label: 'Tribaslim', value: 'Tribaslim' },
  { label: 'Ghutna Jivak', value: 'Ghutna Jivak' },
  { label: 'Udar Sanjivani', value: 'Udar Sanjivani' },
  { label: 'Tribal Black Oil', value: 'Tribal Black Oil' },
  { label: 'Sandhi Sudha Plus Offer', value: 'Sandhi Sudha Plus Offer' },

  ]


  const { isLoading, isFetching, data } = useGetOrderByIdQuery(orderId, { skip: !orderId })
 



  const handleOrder = (e: any) => {
    setOrder(e.target.value)

  }

  const handleSearch = (e: any) => {

    e.preventDefault()
    setOrderid(order)

  }
  useEffect(() => {
    if (!updateOrderInfo.isLoading && !updateOrderInfo.isLoading && !updateOrderInfo.isUninitialized) {
      if (updateOrderInfo?.data?.status) {

        showToast(updateOrderInfo?.data?.msg, "success")
        setOrderData(initialData)
        // window.location.href='/orders'
      } else {
        showToast(updateOrderInfo?.data?.msg, "error")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateOrderInfo])



  useEffect(() => {

    if (!isLoading && !isFetching && data?.data?.length) {

      let initialValues = {
        'product_name': data?.data[0].product_name,
        'name': data?.data[0].name,
        'phone': data?.data[0].phone,
        'email': data?.data[0].email,
        'status': data?.data[0].status,
        'zip_code': data?.data[0].zip_code,
        'state': data?.data[0].state,
        'remark': data?.data[0].remark,
        'quantity': data?.data[0].quantity,
        'address': data?.data[0].address,
        'mode': data?.data[0].mode,
        'city': data?.data[0].city,

      }
      setOrderData(initialValues)
    }



  }, [isLoading, isFetching,data])

  return (
    <SideNavLayout>
      <div className='w-full px-2 py-2 mt-3  h-full overflow-auto'>
        <Paper elevation={3}  >
          <h1 className='mx-5 pt-3 font-bold'>Search order </h1>
          <div className='h-auto flex py-4 items-center justify-start pl-5 '>
            <form  onSubmit={handleSearch}>
              <div className='flex gap-3'>
                <div className='w-[400px]'>
                  <TextField
                    size='small'
                    type='number'
                    onChange={(e) => handleOrder(e)}
                    value={order}
                    name='order_id'
                    inputProps={{ min: 0 }}
                    placeholder='Order id (only number allowed)'
                    fullWidth
                    required
                  />

                </div>
                <div className=' '>
                  <Button
                    type='submit'
                    variant='contained'
                    // onClick={}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </form>
          </div>

          <div className='flex py-2 items-center justify-start'>
            <h1 className='mx-5 font-bold'>Order Details</h1>
          </div>

          {<Formik
            enableReinitialize={true}
            initialValues={{
              'product_name': orderData.product_name,
              'name': orderData.name,
              'phone': orderData.phone,
              'email': orderData.email,
              'status': orderData.status,
              'zip_code': orderData.zip_code,
              'state': orderData.state,
              'remark': orderData.remark,
              'quantity': orderData.quantity,
              'address': orderData.address,
              'mode': orderData.mode,
              'city': orderData.city,
            }}

            onSubmit={(values: any) => {
              setUpdateOrder({ id: orderId, body: values })


            }}

          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue
              /* and other goodies */
            }) => (

              <form onSubmit={handleSubmit}>
                <div className='h-auto  py-4 px-3 items-center justify-start pl-5 '>
                  <div className='grid grid-cols-12 gap-6'>
                    <div className='col-span-6'>

                      <label className="font-bold text-md" htmlFor="bootstrap-input">
                        Product Name
                      </label>
                      <Autocomplete
                        fullWidth
                        disablePortal
                        // value={productOptions.find((ele)=>ele.label===values.product_name)}
                        value={values.product_name}
                        onChange={(e, newValue) => setFieldValue("product_name", newValue)}
                        id="combo-box-demo"
                        options={productOptions}
                        renderInput={(params) => {
                          return (

                            <TextField {...params} />
                          )
                        }
                        }
                      />
                    </div>
                    <div className='col-span-6'>
                      <label className="font-bold text-md" htmlFor="bootstrap-input">
                        Name
                      </label>
                      <TextField
                        size='medium'
                        type='text'
                        onChange={handleChange}
                        name='name'
                        value={values.name}
                        fullWidth
                        required

                      />
                    </div>

                    <div className='col-span-6'>
                      <label className="font-bold text-md" htmlFor="bootstrap-input">
                        Phone
                      </label>
                      <TextField
                        size='medium'
                        type='text'
                        value={values.phone}
                        onChange={handleChange}
                        name='phone'
                        fullWidth
                        required

                      />
                    </div>
                    <div className='col-span-6'>
                      <label className="font-bold text-md" htmlFor="bootstrap-input">
                        Email address
                      </label>
                      <TextField
                        size='medium'
                        type='text'
                        onChange={handleChange}
                        name='email'
                        value={values.email}
                        fullWidth
                        required

                      />
                    </div>
                    <div className='col-span-6'>
                      <label className="font-bold text-md" htmlFor="bootstrap-input">
                        Status
                      </label>
                      <TextField
                        size='medium'
                        type='text'
                        onChange={handleChange}
                        name='status'
                        value={values.status}
                        fullWidth
                        required

                      />
                    </div>
                    <div className='col-span-6'>
                      <label className="font-bold text-md" htmlFor="bootstrap-input">
                        Pincode
                      </label>
                      <TextField
                        size='medium'
                        type='text'
                        onChange={handleChange}
                        name='zip_code'
                        value={values.zip_code}
                        fullWidth
                        required

                      />
                    </div>
                    <div className='col-span-6'>
                      <label className="font-bold text-md" htmlFor="bootstrap-input">
                        City
                      </label>
                      <TextField
                        size='medium'
                        type='text'
                        onChange={handleChange}
                        name='city'
                        value={values.city}
                        fullWidth
                        required

                      />
                    </div>
                    <div className='col-span-6'>
                      <label className="font-bold text-md" htmlFor="bootstrap-input">
                        State
                      </label>
                      <TextField
                        size='medium'
                        type='text'
                        onChange={handleChange}
                        name='state'
                        value={values.state}
                        fullWidth
                        required

                      />
                    </div>
                    <div className='col-span-6'>
                      <label className="font-bold text-md" htmlFor="bootstrap-input">
                        Remark
                      </label>
                      <TextField
                        size='medium'
                        type='text'
                        onChange={handleChange}
                        name='remark'
                        value={values.remark}
                        fullWidth
                        required

                      />
                    </div>
                    <div className='col-span-6'>
                      <label className="font-bold text-md" htmlFor="bootstrap-input">
                        Quantity
                      </label>
                      <TextField
                        size='medium'
                        type='text'
                        onChange={handleChange}
                        name='quantity'
                        value={values.quantity}
                        fullWidth
                        required

                      />
                    </div>
                    <div className='col-span-6'>
                      <label className="font-bold text-md" htmlFor="bootstrap-input">
                        Address
                      </label>
                      <TextField
                        size='medium'
                        type='text'
                        multiline
                        rows={2}
                        onChange={handleChange}
                        name='address'
                        value={values.address}
                        fullWidth
                        required

                      />

                    </div>
                    <div className='col-span-6'>
                      <label className="font-bold text-md" htmlFor="bootstrap-input">
                        Payment Mode
                      </label>
                      <Autocomplete
                        fullWidth
                        value={values.mode}
                        onChange={(e, newValue) => setFieldValue("mode", newValue)}
                        id="combo-box-demo"
                        options={["Online", "COD", "Enquiry", "Call"]}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div>
                  </div>
                </div>

                <div className='flex py-6 mx-4 items-end justify-end'>
                  <Button
                    type='submit'
                    variant='contained'
                  >
                    submit
                  </Button>
                </div>
              </form>)
            }
          </Formik>}
        </Paper>
        <hr />


      </div >
    </SideNavLayout >
  )
}

export default AgentView